/**
 * 模块名称: 合同模板管理模块
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import api from '@/api'
import {
  Table,
  Form,
  Input,
  DatePicker,
  Select,
  Popconfirm,
  Row,
  Col
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import BtnGroup from '@/components/BtnGroup'
import '../../assets/contract.scss'

const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
const { RangePicker } = DatePicker

//筛选初始值
let exporfilter = {
  name: '',
  type: '',
  status: '',
  addTime: ''
}

const Templates = (props) => {

  const { match, location, history } = props
  const { getFieldDecorator, resetFields, validateFields } = props.form

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [statusVal, setStatusVal] = useState('请选择')
  const [temptType, setTemptType] = useState([])//合同状态
  const [contractType, setContractType] = useState([])//合同类型

  const search = parseSearch(location.search)

  useEffect(() => {
    currentPage = search.page ? +search.page : 1
    pageSize = search.limit ? +search.limit : 10
    exporfilter = {
      name: '',
      type: '',
      status: '',
      addTime: ''
    }
    getFortuneContractTemptList()
    onCommonStaffAlteration()
  }, [])

  //获取模板管理列表
  const getFortuneContractTemptList = () => {
    // console.log(exporfilter)
    setLoading(true)
    api.getFortuneContractTemptList({
      limit: pageSize,
      page: currentPage,
      name: exporfilter.name,
      type: exporfilter.type,
      status: exporfilter.status + '',
      addTime: exporfilter.addTimeMin == '' || exporfilter.addTimeMin == undefined ? '' : exporfilter.addTimeMin + '~' + exporfilter.addTimeMax,
    }).then(data => {
      // console.log('data', data)
      setList(data.list)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    api.getFortuneContractTempStatus().then(data => {
      // console.log(data)
      setTemptType(data.TemptType)
      setContractType(data.contractType)
    })
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //const values = Object.values(vals)
      //console.log(values)
      if (!err) {
        // console.log(vals)
        currentPage = 1
        history.replace(match.path)
        exporfilter.name = vals.name || vals.name == 0 ? vals.name : ''//eslint-disable-line
        exporfilter.type = vals.type || vals.type == 0 ? vals.type : ''//eslint-disable-line
        exporfilter.status = vals.status || vals.status == 0 ? vals.status : ''//eslint-disable-line
        exporfilter.addTimeMin = vals.addTime || vals.addTime == 0 ? vals.addTime[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.addTimeMax = vals.addTime || vals.addTime == 0 ? vals.addTime[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        getFortuneContractTemptList()
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter = {
      name: '',
      type: '',
      status: '',
      addTime: ''
    }
    resetFields()
    currentPage = 1
    history.replace(match.path)
    getFortuneContractTemptList()
    setStatusVal('请选择')
  }

  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getFortuneContractTemptList()
  }

  //启用或禁用
  const confirmTemplate = (text) => {
    // console.log(text)
    if (text.status == 1) {
      api.getFortuneContractEnable({ id: text.id }).then(res => {
        getFortuneContractTemptList()
        // console.log(res)
      })
    } else {
      api.getFortuneContractDisable({ id: text.id }).then(res => {
        getFortuneContractTemptList()
        // console.log(res)
      })
    }
  }

  return (
    <div>
      <div className="contract-warp">
        <div className="contract-title">其他选项：</div>
        <Form onSubmit={formSubmit} >
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label="合同名称">
                {getFieldDecorator('name')(<Input placeholder="请输入" style={{ width: 240 }} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="合同类型">
                {getFieldDecorator('type')(
                  <Select placeholder="请选择" style={{ width: 240 }} dropdownMatchSelectWidth={false}>
                    {
                      contractType.map((v, i) => {
                        return <Option value={v.id} key={i}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="合同状态" >
                {getFieldDecorator('status')(
                  <Select placeholder="请选择" style={{ width: 240 }} dropdownMatchSelectWidth={false}>
                    {
                      temptType.map((v, i) => {
                        return <Option value={v.id} key={i}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="创建时间">
                {getFieldDecorator('addTime')(<RangePicker allowClear={false} style={{ width: 240 }} />)}
              </FormItem>
            </Col>
          </Row>
          <div className="contract-filter">
            <BtnGroup cancelName="重置" confirmName="查询" onCancel={onReset} />
          </div>
        </Form>
      </div>
      <div className="line"></div>
      <Table
        dataSource={list}
        rowKey="id"
        className="corp-table"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        onChange={onChangeTable}
      >
        <Column title="合同名称" dataIndex="name" width={300} render={(text, record) => (
          <input type="submit" value={text} />
        )} />
        <Column title="合同类型" dataIndex="typeName" width={300} />
        <Column title="创建时间" dataIndex="addTime" width={300} />
        <Column title="合同状态" dataIndex="StatusName" width={300} />
        <Column title="操作" key="set" width={200} render={(text, record) => (
          <div>
            <Link to={`/protocol/contract/templates/redact?id=${record.id}`} style={{ cursor: 'pointer' }}>
              <span style={{ marginRight: 20, color: '#439DDD' }}></span>
            </Link>
            {
              record.status == 1 ? <Popconfirm
                title="请确认是否禁用（或启用）该合同。"
                onConfirm={() => confirmTemplate(record)}
                okText="确定"
                cancelText="取消"
              >
                <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }}></span>
              </Popconfirm> :
                <Popconfirm
                  title="请确认是否禁用（或启用）该合同。"
                  onConfirm={() => confirmTemplate(record)}
                  okText="确定"
                  cancelText="取消"
                >
                  <span style={{ marginRight: 5, color: '#439DDD', cursor: 'pointer' }}></span>
                </Popconfirm>
            }
          </div>
        )} />
      </Table>
    </div>
  )
}

export default Form.create()(Templates)