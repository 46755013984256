import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Templates from './Templates'
import Preview from './Preview'
import Redact from './Redact'

const TemplateIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/templates'} component={Templates} />
      <Route path={match.path + '/preview'} component={Preview} />
      <Route path={match.path + '/redact'} component={Redact} />
      <Route component={Templates} />
    </Switch>
  )
}

export default TemplateIndex