/**
 * 模块名称: 合同管理模块
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import api from '@/api'
import moment from 'moment'
import {
  Table,
  Form,
  Button,
  Input,
  Icon,
  DatePicker,
  Select,
  message,
  Alert,
  Popconfirm,
  Drawer,
  Spin,
  Upload,
  Row,
  Col,
  Tooltip,
  Modal,
  Switch
} from 'antd'
import { Link } from 'react-router-dom'
import { parseSearch } from '@/utils'
import { upLoadModule } from '@/utils/common'
import Auth from '@/components/AuthMiddleware'
import CorpFilter from '@/components/CorpFilter'
import BtnGroup from '@/components/BtnGroup'
import Export from '@/components/Export'
import ContractAmend from './ContractAmend'
import CryptoJS from 'crypto-js'

const { Dragger } = Upload
const { Column } = Table
const FormItem = Form.Item
const { Option } = Select
let pageSize = 10
let currentPage = 1
let shouldMe = null
let apiOperateTimer = true

const { RangePicker } = DatePicker

let selectedIds = ''

//所属公司筛选
let filter = {
  companyId: ''
}

let params = {}


//筛选初始值
let exporfilter = {
  contractNo: '',
  contract_no: '',
  applyName: '',
  customerName: '',
  liablerName: '',
  staffNo: '',
  departId: '',
  businessStatus: '',
  auditStatus: '',
  templateName: '',
  contentId: '',
  applyDate: '',
  collectionDate: '',
  backdata: ''
}

const Contract = (props) => {

  const { match, location, history } = props
  const { getFieldDecorator, resetFields, setFieldsValue, validateFields } = props.form

  const productsRef = useRef()

  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const [count, setCount] = useState(0)
  const [department, setDepartment] = useState([])
  const [expand, setExpand] = useState(false)
  const [alterationUnfoldPack, setAlterationUnfoldPack] = useState(true)
  const [alterationTextUnfoldPack, setAlterationTextUnfoldPack] = useState(true)
  const [showExport, setShowExport] = useState(false)
  const [checked, setChecked] = useState(0)
  const [contractStatus, setContractStatus] = useState([])
  const [temptName, setTemptName] = useState([])//合同模板
  const [accountList, setAccountList] = useState([])
  const [approvalStatusList, setApprovalStatusList] = useState([])//审批状态
  const [visible, setVisible] = useState(false)
  const [visibleSave, setVisibleSave] = useState(false)
  const [formLoading, setformLoading] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [execid, setExecid] = useState('')
  const [orderForm, setorderForm] = useState('')
  const [checkedIdArr, setCheckedIdArr] = useState([])
  const [ids, setIds] = useState([])
  const [checkedId, setCheckedId] = useState('')
  const [authList, setAuthList] = useState([])
  const [statusVal, setStatusVal] = useState('请选择')
  const [flag, setFlag] = useState(false)
  const [filterContent, setFilterContent] = useState([])
  const [statusValName, setStatusValName] = useState(undefined)
  const [filterInfoValue, setFilterInfoValue] = useState('')
  const [shouldMeDis, setShouldMeDis] = useState(true)//风控，防止用户频繁点击switch
  const [visibleAmend, setVisibleAmend] = useState(false)
  const [formLoadingAmend, setformLoadingAmend] = useState(false)

  const search = parseSearch(location.search)
  
  useEffect(() => {
    filter = {
      companyId: ''
    }
    // console.log(history.location.state)
    if (!history.location.state) {
      currentPage = search.page ? +search.page : 1
      pageSize = search.limit ? +search.limit : 10
      exporfilter = {
        contractNo: '',
        contract_no: '',
        applyName: '',
        customerName: '',
        liablerName: '',
        staffNo: '',
        departId: '',
        businessStatus: '',
        auditStatus: '',
        templateName: '',
        contentId: '',
        applyDate: '',
        collectionDate: '',
        backdata: ''
      } 
      shouldMe = null
    } else {
      currentPage = search.page ? +search.page : currentPage
      pageSize = search.limit ? +search.limit : pageSize
      // console.log(exporfilter)
      setTimeout(() => {
        setFieldsValue({
          contractNo: exporfilter.contractNo !== '' ? exporfilter.contractNo : undefined,
          contract_no: exporfilter.contract_no !== '' ? exporfilter.contract_no : undefined,
          applyName: exporfilter.applyName !== '' ? exporfilter.applyName : undefined,
          customerName: exporfilter.customerName !== '' ? exporfilter.customerName : undefined,
          liablerName: exporfilter.liablerName !== '' ? exporfilter.liablerName : undefined,
          staffNo: exporfilter.staffNo !== '' ? exporfilter.staffNo : undefined,
          departId: exporfilter.departId !== '' ? exporfilter.departId : undefined,
          businessStatus: exporfilter.businessStatus !== '' ? exporfilter.businessStatus : undefined,
          auditStatus: exporfilter.auditStatus !== '' ? exporfilter.auditStatus : undefined,
          templateName: exporfilter.templateName !== '' ? exporfilter.templateName : undefined,
          contentId: exporfilter.contentId !== '' ? exporfilter.contentId : undefined,
          applyDate: exporfilter.applyDate !== '' ? [moment(exporfilter.applyDate.split('~')[0]), moment(exporfilter.applyDate.split('~')[1])] : undefined,
          collectionDate: exporfilter.collectionDate !== '' ? [moment(exporfilter.collectionDate.split('~')[0]), moment(exporfilter.collectionDate.split('~')[1])] : undefined,
          backdata: exporfilter.backdata !== '' ? [moment(exporfilter.backdata.split('~')[0]), moment(exporfilter.backdata.split('~')[1])] : undefined
        })
      }, 1000)
    }
    apiOperateTimer = true
    onCommonStaffAlteration()
    getEmployeeList()
    api.getPageAuth().then(list => setAuthList(list))
  }, [])

  //获取合同列表
  const getFortuneSupplyAgreementList = () => {
    if(apiOperateTimer){
      setLoading(true)
      let params = {
        limit: pageSize,
        page: currentPage,
        companyId: filter.companyId
      }
      if(shouldMe){
        params.shouldMe = shouldMe
      }else{
        for(let i in params){
          if(i == 'shouldMe')delete params[i]
        }
      }
      api.getFortuneContractList(params).then(data => {
        // console.log('data', data)
        setList(data.list)
        if (currentPage === 1) {
          if (data.ids) {
            localStorage.setItem('ids', JSON.stringify(data.ids))
          } else {
            localStorage.setItem('ids', JSON.stringify([]))
          }
        }
        setIds(data.ids)
        setChecked(0)
        setCheckedIdArr([])
        setCount(data.count)
        setLoading(false)
      }).catch(() => setLoading(false))
      apiOperateTimer = false
      setShouldMeDis(false)
      let timer = setTimeout(() => {
        apiOperateTimer = true
        setShouldMeDis(true)
        clearTimeout(timer)
      }, 1000)
    }else{
      return message.info('请不要频繁操作')
    }
  }

  const onSetShouldMe = e => {
    if(e == true){
      shouldMe = 1
    }else{
      shouldMe = null
    }
    getFortuneSupplyAgreementList()
  }

  //获取公共接口
  const onCommonStaffAlteration = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartment(data.list)) //部门
    api.getFortuneContractApplyStatus().then(data => {
      // console.log(data)
      setContractStatus(data.ContractStatus)
      setAccountList(data.accountList)
    })
    api.getFortuneContractTempStatus().then(data => {
      // console.log('getFortuneContractTempStatus', data)
      setTemptName(data.temptName)
    })
    api.getAuditStatusList({type: '33'}).then(data => {
      // console.log('getFortuneContractApprovalStatusList', data)
      setApprovalStatusList(data)
    })
  }

  //筛选时调接口
  const getEmployeeList = () => {
    // console.log(exporfilter)
    if(shouldMe){
      params.shouldMe = shouldMe
    }else{
      for(let i in params){
        if(i == 'shouldMe')delete params[i]
      }
    }
    setLoading(true)
    api.getFortuneContractList({
      limit: pageSize,
      page: currentPage,
      companyId: filter.companyId,
      contractNo: exporfilter.contractNo,
      contract_no: exporfilter.contract_no,
      applyName: exporfilter.applyName,
      customerName: exporfilter.customerName,
      liablerName: exporfilter.liablerName,
      staffNo: exporfilter.staffNo,
      departId: exporfilter.departId,
      businessStatus: exporfilter.businessStatus,
      auditStatus: exporfilter.auditStatus,
      templateName: exporfilter.templateName,
      contentId: exporfilter.contentId,
      applyDate: exporfilter.applyDate,
      collectionDate: exporfilter.collectionDate,
      backdata: exporfilter.backdata,
      shouldMe: shouldMe ? shouldMe : ''
    }).then(data => {
      setList(data.list)
      if (currentPage === 1) {
        if (data.ids) {
          localStorage.setItem('ids', JSON.stringify(data.ids))
        } else {
          localStorage.setItem('ids', JSON.stringify([]))
        }
      }
      if (data.ids.length > 0) setIds(data.ids)
      setCount(data.count)
      setLoading(false)
    }).catch(() => setLoading(false))
  }

  //点击筛选提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      //const values = Object.values(vals)
      //console.log(values)
      if (!err) {
        // console.log(vals)
        currentPage = 1
        history.replace(match.path)
        exporfilter.contractNo = vals.contractNo || vals.contractNo == 0 ? vals.contractNo : ''//eslint-disable-line
        exporfilter.contract_no = vals.contract_no || vals.contract_no == 0 ? vals.contract_no : ''//eslint-disable-line
        exporfilter.applyName = vals.applyName || vals.applyName == 0 ? vals.applyName : ''//eslint-disable-line
        exporfilter.customerName = vals.customerName || vals.customerName == 0 ? vals.customerName : ''
        exporfilter.liablerName = vals.liablerName || vals.liablerName == 0 ? vals.liablerName : ''
        exporfilter.staffNo = vals.staffNo || vals.staffNo == 0 ? vals.staffNo : ''//eslint-disable-line
        exporfilter.departId = vals.departId || vals.departId == 0 ? vals.departId : ''//eslint-disable-line
        exporfilter.businessStatus = vals.businessStatus || vals.businessStatus == 0 ? vals.businessStatus : ''//eslint-disable-line
        exporfilter.auditStatus = vals.auditStatus || vals.auditStatus == 0 ? vals.auditStatus : ''//eslint-disable-line
        exporfilter.templateName = vals.templateName || vals.templateName == 0 ? vals.templateName : ''//eslint-disable-line
        exporfilter.contentId = vals.contentId || vals.contentId == 0 ? vals.contentId : ''//eslint-disable-line
        exporfilter.applyDate = vals.applyDate || vals.applyDate == 0 ? vals.applyDate[0].format('YYYY-MM-DD') + '~' + vals.applyDate[1].format('YYYY-MM-DD') : ''
        // exporfilter.applyDateMin = vals.applyDate || vals.applyDate == 0 ? vals.applyDate[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        // exporfilter.applyDateMax = vals.applyDate || vals.applyDate == 0 ? vals.applyDate[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.collectionDate = vals.collectionDate || vals.collectionDate == 0 ? vals.collectionDate[0].format('YYYY-MM-DD') + '~' + vals.collectionDate[1].format('YYYY-MM-DD') : ''
        // exporfilter.collectionDateMin = vals.collectionDate || vals.collectionDate == 0 ? vals.collectionDate[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        // exporfilter.collectionDateMax = vals.collectionDate || vals.collectionDate == 0 ? vals.collectionDate[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        exporfilter.backdata = vals.backdata || vals.backdata == 0 ? vals.backdata[0].format('YYYY-MM-DD') + '~' + vals.backdata[1].format('YYYY-MM-DD') : ''
        // exporfilter.backdataMin = vals.backdata || vals.backdata == 0 ? vals.backdata[0].format('YYYY-MM-DD') : ''//eslint-disable-line
        // exporfilter.backdataMax = vals.backdata || vals.backdata == 0 ? vals.backdata[1].format('YYYY-MM-DD') : ''//eslint-disable-line
        getEmployeeList()
        setChecked(0)
        setCheckedIdArr([])
      }
    })
  }

  const onReset = (e) => {
    e.preventDefault()
    exporfilter = {
      contractNo: '',
      contract_no: '',
      applyName: '',
      customerName: '',
      liablerName: '',
      staffNo: '',
      departId: '',
      businessStatus: '',
      auditStatus: '',
      templateName: '',
      contentId: '',
      applyDate: '',
      collectionDate: '',
      backdata: ''
    }
    resetFields()
    shouldMe = null
    currentPage = 1
    history.replace(match.path)
    setStatusVal(undefined)
    setStatusValName(undefined)
    getEmployeeList()
    setChecked(0)
    setCheckedIdArr([])
  }

  const toggle = () => {
    setExpand(!expand)
    setAlterationUnfoldPack(!alterationUnfoldPack)
    setAlterationTextUnfoldPack(!alterationTextUnfoldPack)
  }

  //导出
  const onExportFile = () => {
    setShowExport(true)
    if (filter.companyId && filter.companyId.length > 0) {
      if (!Array.isArray(filter.companyId)) {
        filter.companyId = filter.companyId.split(',')
      }
    } else {
      filter.companyId = []
    }
    Object.assign(exporfilter, filter)
    // console.log(exporfilter)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows)
      setCheckedId(selectedRowKeys.join(","))
      setChecked(selectedRowKeys.length)
      //setCheckedId(checkedIdArr.join(","))
      selectedIds = selectedRowKeys
    },
    onSelect: (record, selected, selectedRows) => {
      // console.log(record, selected, selectedRows)
      //let arr = []
      if (!selected) {
        for (let i = 0; i < checkedIdArr.length; i++) {
          if (checkedIdArr[i] == record.id) {//eslint-disable-line
            checkedIdArr.splice(i, 1)
            i--
          }
        }
      } else {
        checkedIdArr.push(record.id)
      }
      let arrNew = [...new Set(checkedIdArr)]
      setCheckedIdArr(arrNew)
      setChecked(arrNew.length)
      selectedIds = arrNew
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
    //全选一列（默认十条）
    onSelectAll: (selected, selectedRows, changeRows) => {
      //console.log(selected, selectedRows, changeRows)
      let arr = []
      changeRows.forEach(item => {
        arr.push(item.id)
      })
      if (selected) {
        setCheckedIdArr([...new Set([...checkedIdArr, ...arr])])
      } else {
        setCheckedIdArr(checkedIdArr.filter(item => !arr.includes(item)))
      }
      // setChecked(arr.length)
      // selectedIds = arr
    },
    selectedRowKeys: checkedIdArr
  }

  //筛选
  const onCorpFilter = (data) => {
    // setDepartment([])
    // setFieldsValue({'departId': undefined})
    filter.companyId = data.map(item => item.id).join(',')
    history.replace(match.path)
    currentPage = 1
    getFortuneSupplyAgreementList()
  }

  //选中所属公司后部门下拉框内容随之变化
  const onDepartmentId = () => {
    api.getSelectDepartmentList({ limit: global.paramsLimit }).then(data => setDepartment(data.list)) //部门
  }

  const onChangeTable = (pagination) => {
    // console.log(pagination)
    currentPage = pagination.current
    pageSize = pagination.pageSize
    history.replace(match.path + '?page=' + currentPage + '&limit=' + pageSize)
    getEmployeeList()
  }

  //反选
  const onReverse = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i].id
    }
    let filterIds = []
    arr.forEach(item => {
      if (!checkedIdArr.includes(item)) {
        filterIds.push(item)
      }
    })
    setCheckedIdArr(filterIds)
    setChecked(filterIds.length)
    selectedIds = filterIds
  }

  //全选
  const onAllelection = () => {
    let IDS = JSON.parse(localStorage.getItem('ids'))
    let arr = []
    for (let i = 0; i < IDS.length; i++) {
      arr[i] = IDS[i].id
    }
    setChecked(arr.length)
    setCheckedIdArr(arr)
    //console.log(arr)
    selectedIds = arr
  }

  const onDelete = (id) => {
    // console.log(id)
    api.getFortuneContractDel({ id: id }).then(() => {
      message.success('删除成功')
      getEmployeeList()
    })
  }

  const afterVisibleChange = (isOpen) => {
    if (isOpen) {

    }
  }

  //取消
  const onRelevance = () => {
    setVisible(false)
  }

  //补充确定按钮
  const onConfirmReplenish = () => {
    setVisible(false)
    // console.log(fileList)
    api.getFortuneSupplyAgreementAddOrder({
      exId: execid,
      orderNo: orderForm,
      status: 5,
      fileData: JSON.stringify(fileList)
    }).then(data => {
      // console.log(data)
    }).catch(error => {
      // console.log(error)
    })
  }

  // 文件上传 
  const uploadFiles = upLoadModule({
		fileList: fileList,
		setFileList: setFileList,
		draggerRef: draggerRef,
		accept: '.gif, .png, .jpg, .jepg, .PDF, .doc, .docx, .csv, .xlsx, .zip',
		allowSizeType: 2
	})

  const onTextArea = (e) => {
    // console.log(e.target.value)
    setorderForm(e.target.value)
  }

  const onChecked = (value, val2) => {
    setStatusVal(value)
    setStatusValName(val2.props.children.props.children[0])
    let filterCont = filterContent[value].content

    setFieldsValue({
      contractNo: filterCont.contractNo || filterCont.contractNo == 0 ? filterCont.contractNo : undefined,//eslint-disable-line
      contract_no: filterCont.contract_no || filterCont.contract_no == 0 ? filterCont.contract_no : undefined,//eslint-disable-line
      applyName: filterCont.applyName || filterCont.applyName == 0 ? filterCont.applyName : undefined,//eslint-disable-line
      customerName: filterCont.customerName || filterCont.customerName == 0 ? filterCont.customerName : undefined,
      liablerName: filterCont.liablerName || filterCont.liablerName == 0 ? filterCont.liablerName : undefined,
      staffNo: filterCont.staffNo || filterCont.staffNo == 0 ? filterCont.staffNo : undefined,//eslint-disable-line
      departId: filterCont.departId || filterCont.departId == 0 ? filterCont.departId : undefined,//eslint-disable-line
      businessStatus: filterCont.businessStatus || filterCont.businessStatus == 0 ? filterCont.businessStatus : undefined,//eslint-disable-line
      auditStatus: filterCont.auditStatus || filterCont.auditStatus == 0 ? filterCont.auditStatus : undefined,//eslint-disable-line
      templateName: filterCont.templateName || filterCont.templateName == 0 ? filterCont.templateName : undefined,//eslint-disable-line
      contentId: filterCont.contentId || filterCont.contentId == 0 ? filterCont.contentId : undefined,//eslint-disable-line
      applyDate: filterCont.applyDate || filterCont.applyDate == 0 ? [moment(filterCont.applyDate[0]), moment(filterCont.applyDate[1])] : undefined, //eslint-disable-line
      collectionDate: filterCont.collectionDate || filterCont.collectionDate == 0 ? [moment(filterCont.collectionDate[0]), moment(filterCont.collectionDate[1])] : undefined, //eslint-disable-line
      backdata: filterCont.backdata || filterCont.backdata == 0 ? [moment(filterCont.backdata[0]), moment(filterCont.backdata[1])] : undefined //eslint-disable-line
    })//选择保存筛选下拉框中的内容

  }

  // 筛选删除
  const onSearchDelete = (id) => {
    api.searchStorageDelete({
      id: id
    }).then(() => {
      api.getSearchStorage({ key: location.pathname }).then(data => {
        setFilterContent(data.list)
      })
    })
  }

  const onChangeGetFilter = (val) => {
    val ? setFlag(true) : setFlag(false)
    //展开保存筛选下拉框获取存储过的搜索内容
    val && api.getSearchStorage({ key: location.pathname }).then(data => {
      setFilterContent(data.list)
    })
  }

  const onSaveFilterTerm = () => {
    validateFields((err, vals) => {
      if (!err) {
        const values = Object.values(vals)
        if (values.some(item => item !== undefined)) {
          params = {}
          for (let k in vals) {//eslint-disable-line
            if (vals[k] !== undefined) {
              if (k === 'time') {
                params[k] = [moment(vals[k][0]).format('YYYY-MM-DD'), moment(vals[k][1]).format('YYYY-MM-DD')]
              } else if (k === 'month') {
                // console.log(typeof (moment(vals[k]).format('YYYY-MM')))
                params[k] = moment(vals[k]).format('YYYY-MM')
              } else {
                params[k] = vals[k]
              }

            }
          }
          setVisibleSave(true)
        } else {
          message.error('当前没有设置筛选条件')
        }
      }
    })
  }

  // 弹出框确定
  const HandleOk = () => {
    api.saveSearchStorage({
      name: filterInfoValue,
      key: location.pathname,
      content: params
    }).then(data => {
      setVisibleSave(false)
      message.success('添加成功')
    })
  }

  const HandleCancel = () => {
    setVisibleSave(false) //弹出框内取消
  }

  const FilterInfoChange = (e) => {
    setFilterInfoValue(e.target.value) //弹框input值
  }

  //合同修改弹出抽屉
  const onClickAmend = () => {
    setVisibleAmend(true)
  }

  return (
    <div className="">
      <CorpFilter onChange={onCorpFilter} />
      <div className="contract-warp">
        <h4 className="title">其它选项：</h4>
        <span className="contract-btn-a" onClick={toggle}>
          {alterationTextUnfoldPack ? '展开' : '收起'}<Icon type={expand ? 'up' : 'down'} />
        </span>
        <Form onSubmit={formSubmit} >
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label="业务编号">
                {getFieldDecorator('contractNo')(<Input placeholder="请输入" style={{ width: 230 }} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="合同号">
                {getFieldDecorator('contract_no')(<Input placeholder="请输入" style={{ width: 230 }} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="客户名称">
                {getFieldDecorator('customerName')(<Input placeholder="请输入" style={{ width: 230 }} />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={8}>
              <FormItem label="申请人">
                {getFieldDecorator('applyName')(<Input placeholder="请输入" style={{ width: 230 }} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="员工编号">
                {getFieldDecorator('staffNo')(<Input placeholder="请输入" style={{ width: 230 }} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="所属部门">
                {getFieldDecorator('departId')(
                  <Select 
                    placeholder="请选择" 
                    style={{ width: 230 }} 
                    dropdownMatchSelectWidth={false} 
                    onDropdownVisibleChange={bool => bool && onDepartmentId()}
                    showSearch={true}
                    optionFilterProp="children"
                  >
                    {
                      department.map(v => {
                        return <Option title={v.name + '（' + v.companyName + '）'} key={v.id}>{v.name}（{v.companyName}）</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24} className={alterationUnfoldPack ? "contract-unfold" : ""}>
            <Col span={8}>
              <FormItem label="责任人">
                {getFieldDecorator('liablerName')(<Input placeholder="请输入" style={{ width: 230 }} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="业务状态" >
                {getFieldDecorator('businessStatus')(
                  <Select placeholder="请选择" style={{ width: 230 }} dropdownMatchSelectWidth={false}>
                    {
                      contractStatus.map((v, i) => {
                        return <Option value={v.id} key={i}>{v.name}</Option>
                      })
                    }
                  </Select>)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="审批状态">
                {getFieldDecorator('auditStatus')(<Select placeholder="请选择" style={{ width: 230 }} dropdownMatchSelectWidth={false} showSearch={true} optionFilterProp="children">
                  {
                    approvalStatusList.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24} className={alterationUnfoldPack ? "contract-unfold" : ""}>
            <Col span={8}>
              <FormItem label="合同模板">
                {getFieldDecorator('templateName')(<Select placeholder="请选择" style={{ width: 230 }} dropdownMatchSelectWidth={false}>
                  {
                    temptName.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="合同负责人主体">
                {getFieldDecorator('contentId')(<Select placeholder="请选择" style={{ width: 230 }} dropdownMatchSelectWidth={false}>
                  {
                    accountList.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="申请时间">
                {getFieldDecorator('applyDate')(<RangePicker allowClear={false} style={{ width: 230 }} />)}
              </FormItem>
            </Col>
          </Row>
          <Row gutter={24} className={alterationUnfoldPack ? "contract-unfold" : ""}>
            <Col span={8}>
              <FormItem label="领取时间">
                {getFieldDecorator('collectionDate')(<RangePicker allowClear={false} style={{ width: 230 }} />)}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem label="返回时间">
                {getFieldDecorator('backdata')(<RangePicker allowClear={false} style={{ width: 230 }} />)}
              </FormItem>
            </Col>
          </Row>
          <div className="contract-filter">
            <h4 className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>已保存筛选方案：</h4>
            <Select
              placeholder="默认请选择"
              style={{ width: 160 }}
              value={statusVal}
              onDropdownVisibleChange={bool => bool && onChangeGetFilter(bool)}
              onSelect={onChecked}
              className={alterationUnfoldPack ? "staffalteration-unfold" : ""}

              dropdownMatchSelectWidth={false}
            >
              {
                filterContent.map((item, index) =>
                <Option value={index} key={index}>
                  <div className="closeStyBtn">
                    {item.name}
                    {flag && statusValName !== item.name ? <span className="closeSpan"><Icon style={{ fontSize: '10px', color: '#1890ff' }} type="close" onClick={e => {
                      e.stopPropagation()
                      onSearchDelete(item.id)
                    }} /></span> : null}
                  </div>
                </Option>
              )
              }
            </Select>
            <Button onClick={onSaveFilterTerm} className={alterationUnfoldPack ? "staffalteration-unfold" : ""}>保存筛选条件</Button>
            <Switch 
            checkedChildren="待审批" 
            unCheckedChildren="待审批" 
            disabled={!shouldMeDis} 
            onClick={onSetShouldMe} 
            className="shouldme-switch-btn" 
            checked={shouldMe ? true : false} 
          />
            <BtnGroup cancelName="重置" confirmName="筛选" onCancel={onReset} />
          </div>
          <Modal
            title="保存筛选条件"
            visible={visibleSave}
            onOk={HandleOk}
            onCancel={HandleCancel}
          >
            <Input placeholder="请输入筛选名称" value={filterInfoValue} onChange={FilterInfoChange} />
          </Modal>
        </Form>
      </div>
      <div className="line"></div>
      <div className="add-wrap">
        <Auth auths={authList} code="contract-edit">
          <Button type="primary" style={{ marginLeft: 15 }}>
            <Link to={`${match.path}/contractAmend`} style={{ cursor: 'pointer' }}>合同修改</Link>
          </Button>
        </Auth>
        <Auth auths={authList} code="template-manage">
          <Link to={`${match.path}/templates`} style={{ cursor: 'pointer' }}>
            <Button type="primary" style={{ marginLeft: 15 }}>模板管理</Button>
          </Link>
        </Auth>
        <Auth auths={authList} code="export">
          <Button type="primary" onClick={onExportFile} style={{ marginLeft: 15 }}>导出</Button>
        </Auth>
        <Auth auths={authList} code="staff-attendance-apply">
          <Link to={`${match.path}/apply`} style={{ cursor: 'pointer' }}>
            <Button type="primary" style={{ marginLeft: 15 }}>申请</Button>
          </Link>
        </Auth>
      </div>
      <Alert className="corp-count" message={
        <>
          <span>已选择 <span style={{ color: '#168FFF' }}>{checked}</span> 项</span>
          {checked !== 0 && <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onReverse}>反选</span>}
          {checked == 0 //eslint-disable-line
            && <span style={{ marginLeft: 10, color: '#ccc', cursor: 'default' }}>反选</span>}
          <span style={{ marginLeft: 10, color: '#168FFF', cursor: 'pointer' }} onClick={onAllelection}>全选</span>
        </>
      } type="info" showIcon />
      <Drawer
         title="合同修改"
         width={1200}
         onClose={() => setVisibleAmend(false)}
         visible={visibleAmend}
      >
         <Spin spinning={formLoadingAmend}>
           <ContractAmend/>
         </Spin>
      </Drawer>
      <Table
        size="small"
        dataSource={list}
        rowKey="id"
        loading={loading}
        pagination={{
          pageSize,
          total: count,
          current: currentPage,
          showQuickJumper: true,
          showSizeChanger: true,
          pageSizeOptions: ['10', '50', '100', '500', '1000', '2000']
        }}
        scroll={{ x: 2800 }}
        onChange={onChangeTable}
        rowSelection={rowSelection}
      >
        <Column title="业务编号" dataIndex="businessNo" fixed="left" width={180} render={(text, record) => (
          <Link to={`/protocol/contract/details?id=${record.id}&new=1&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} style={{ cursor: 'pointer' }}>{text}</Link>
        )} />
        <Column title="合同号" dataIndex="contractNo" fixed="left" width={160} />
        <Column title="合同主体" dataIndex="accountName" fixed="left" width={150} />
        <Column title="合同模板" dataIndex="templateTypeName" width={180}/>
        <Column title="甲方" dataIndex="partA" width={220}/>
        <Column title="乙方" dataIndex="partB" width={220}/>
        <Column title="申请人" dataIndex="applyName" width={140}/>
        <Column title="员工编号" dataIndex='staffNo' width={160}/>
        <Column title="所属部门" dataIndex="departName" width={200}/>
        <Column title="领取人" dataIndex="receiver" />  
        <Column title="领取人职位" dataIndex="receiverJobPosition" /> 
        <Column title="责任人员工编号" dataIndex="liablerStaffNo" width={120}/> 
        <Column title="责任人" dataIndex="liablerName" />
        <Column title="责任人职位" dataIndex="liablerJobPosition" />
        <Column title="一式几份" dataIndex="copies" />
        <Column title="业务状态" dataIndex="businessStatusName" />
        <Column title="审批状态" dataIndex="auditStatusName" />
        <Column title="申请时间" dataIndex="applyDate" />
        <Column title="领取时间" dataIndex="collectionDate" />
        <Column title="返回时间" dataIndex="backDate" />
        <Column title="操作" key="set" fixed="right" width={100} render={(text, record) => (
          <>
            <Tooltip title="在新页面打开详情页" placement="bottom">
              <Link target='_blank' to={`/protocol/contract/details?id=${record.id}&time=${+new Date()}&sign=${CryptoJS.MD5(record.id+'ad629fddf8b8756d2e72e96ae035a5a4'+(+new Date())).toString()}`} style={{ cursor: 'pointer' }}>
                <Icon type="folder-open" style={{ color: '#1890FF', fontSize: '16px', marginLeft: '8px' }} />
              </Link>
            </Tooltip>
            {
              record.editFlag && <Link to={`/protocol/contract/apply?id=${record.id}`} style={{ cursor: 'pointer' }}>
                <Tooltip title="编辑" placement="bottom">
                  <Icon type="edit" style={{ color: '#1890FF', fontSize: '16px', marginLeft: '12px' }} />
                </Tooltip>
              </Link>
            }
            {
              record.delFlag && <Popconfirm title="确认删除吗？" cancelText="取消" okText="确认" onConfirm={() => onDelete(record.id)}>
                <Tooltip title="删除" placement="bottom">
                  <Icon className="operate-icon" type="delete" style={{ color: 'red', fontSize: '16px', marginLeft: '12px' }}/>
                </Tooltip>
            </Popconfirm>
            }
            
          </>
        )} />
      </Table>
      <Drawer
        title="补充订单信息"
        width={800}
        onClose={() => setVisible(false)}
        visible={visible}
        afterVisibleChange={afterVisibleChange}
      >
        <Spin spinning={formLoading}>
          <div className="spin">
            <div className="drawer">
              <div>订单编号：</div>
              <div>
                <Input.TextArea placeholder="请输入订单编号" maxLength={100} style={{ width: 500, marginLeft: '70px' }} onChange={onTextArea} />
              </div>
            </div>
            <div style={{ marginTop: '40px' }}>
              <div style={{ paddingLeft: '30px' }} > 附件：</div>
              <div className="dragger-box" ref={draggerRef} style={{ width: 500, marginLeft: '70px' }}>
                <Dragger
                  {...uploadFiles}
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                  <p className="ant-upload-hint">
                    支持扩展名：GIF、PNG、JPG、PDF、doc、docx、csv、.xlsx、zip、.rar、JEPG 单个文件大小不超过5M，不超过5个附件。
                  </p>
                </Dragger>
              </div>
            </div>
            <div className="replenish-button" >
              <BtnGroup onCancel={onRelevance} onConfirm={onConfirmReplenish} loading={saveLoading} />
            </div>
          </div>
        </Spin>
      </Drawer>
      <Export
        show={showExport}
        onCancel={() => {
          setShowExport(false)
          productsRef.current.changeVal()
        }}
        tplUrl="getFortuneContractChangeExportTpl"
        fieldsUrl="getFortuneContractChangeTplItems"
        saveUrl="saveFortuneContractChangeExportTpls"
        exportUrl="exportFortuneContractChange"
        method="export"
        parame={{ ExportType: 'contract.export', id: selectedIds, ...exporfilter }}
        type={1}
        cRef={productsRef}
      />
    </div >
  )
}

export default Form.create()(Contract)