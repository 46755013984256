/**
 * 模块名称: 模板管理编辑页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import {
  Button,
  Input,
  Form
} from 'antd'
import api from '@/api'
import '../../assets/template.scss'
import { parseSearch } from '@/utils'
import Editor from 'wangeditor'

const FormItem = Form.Item

const Redact = (props) => {

  const { history, location } = props
  const { getFieldDecorator, validateFields, setFieldsValue } = props.form
  const contentRef = useRef(null)
  const search = parseSearch(location.search)

  const [content, setContent] = useState('')
  const [editorHtml, setEditorHtml] = useState('')
  const [editorText, setEditorText] = useState('')//eslint-disable-line

  useEffect(() => {
    // console.log(search)
    initEditor()
    api.getFortuneContractTemptDetail({ id: search.id }).then(res => {
      // console.log(res)
      setFieldsValue({
        name: res.name
      })
      setContent(res.html)
      //回显富文本内容
      let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
      dealEditor(editor)
      editor.create()
      editor.txt.html(res.html)
    })
  }, [])

  //取消返回列表页
  const onCancel = () => {
    history.push('/protocol/contract/templates')
  }

  //富文本
  const initEditor = () => {
    let editor = new Editor(ReactDOM.findDOMNode(contentRef.current))
    dealEditor(editor)
    editor.create()
  }
  //富文本数据配置
  const dealEditor = (editor) => {
    editor.customConfig.showLinkImg = false
    editor.customConfig.uploadImgServer = '/api/File/upload'
    editor.customConfig.uploadFileName = 'file'
    editor.customConfig.uploadImgHeaders = {
      token: localStorage.getItem('token')
    }
    // 自定义菜单配置
    editor.customConfig.menus = [
      'head',  // 标题
      'bold',  // 粗体
      'fontSize',  // 字号
      'fontName',  // 字体
      'italic',  // 斜体
      'underline',  // 下划线
      'strikeThrough',  // 删除线
      'foreColor',  // 文字颜色
      'backColor',  // 背景颜色
      'link',  // 插入链接
      'list',  // 列表
      'justify',  // 对齐方式
      'quote',  // 引用
      'image',  // 插入图片
      'table',  // 表格
      'code',  // 插入代码
      'undo',  // 撤销
      'redo'  // 重复
    ]
    //插入视频 : 'video'
    //'emoticon',  // 表情
    editor.customConfig.onchange = (html) => {
      //console.log(html)
      setEditorHtml(html)
      setEditorText(editor.txt.text())
      setFieldsValue({
        content: html
      })
    }
    editor.customConfig.uploadImgHooks = {
      before(xhr, editor, files) {
        //console.log(xhr, editor, files)
      },
      success(xhr, editor, result) {
        //console.log(xhr, editor, result)
      },
      fail(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      error(xhr, editor, result) {
        // console.log(xhr, editor, result)
      },
      timeout(xhr, editor) {
        // console.log(xhr, editor)
      },
      customInsert(insertImg, result, editor) {
        //console.log(insertImg, result, editor)
        var url = result.data.full
        insertImg(url)
      }
    }
  }

  //提交表单
  const formSubmit = (e) => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        // console.log(vals)
        api.getFortuneContractEditTempt({
          id: search.id,
          name: vals.name,
          content: vals.content
        }).then(res => {
          // console.log(res)
          history.push('/protocol/contract/templates')
        }).catch(error => console.log(error))
      }
    })
  }

  //合同预览
  const onPreview = () => {
    // console.log('editorHtml', editorHtml)
    api.getFortuneContractTempPreview({ id: search.id, content: editorHtml ? editorHtml : content }).then(res => {
      // console.log(res)
    }).catch(error => console.log(error))
  }

  return (
    <div className="redact">
      <Form onSubmit={formSubmit}>
        <div className="redact-top">
          <FormItem label="合同名称">
            {getFieldDecorator('name')(<Input placeholder="请输入" style={{ width: 245 }} maxLength={100} />)}
          </FormItem>
        </div>
        <div className="line"></div>
        <div className="redact-content">
          <h3 className="redact-tit">合同内容</h3>
          <div className="underline"></div>
          <div className="redact-wangEditor">
            <FormItem label="">
              {getFieldDecorator('content', { initialValue: content })(<div className="wangEditor-container" ref={contentRef}></div>)}
            </FormItem>
          </div>
        </div>
        <div className="redact-btn">
          <FormItem label="">
            <Button onClick={onCancel} style={{ marginLeft: '15px' }}>取消</Button>
            <Button type="primary" style={{ marginLeft: '15px' }} htmlType="submit" >保存</Button>
          </FormItem>
        </div>
      </Form>
      <form id="upload" method="post" action="/api/fortune/Contract/TempPreview" target="_blank">
        <input type="hidden" name="id" value={search.id} />
        <input type="hidden" name="content" value={editorHtml ? editorHtml : content} />
        <Button htmlType="submit">预览</Button>
      </form>
    </div>
  )
}

export default Form.create()(Redact)