/**
 * 模块名称: 合同管理详情页
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect } from 'react'
import {
  Descriptions,
  Spin,
  Icon,
  message,
  Steps,
  Button,
  Modal,
  Input,
  Popconfirm,
  Form,
  Select,
  Table,
  Row,
  Col
} from 'antd'
import api from '@/api'
import { useSelector } from 'react-redux'
import { parseSearch, debounce } from '@/utils'
import CryptoJS from 'crypto-js'

const FormItem = Form.Item
const {Option} = Select
const { Step } = Steps
const dItem = Descriptions.item

let areaTxt = '', areaTxts = '', result = ''
let receiveTimer = null

const Details = (props) => {

  const { history, location } = props
  const { getFieldDecorator } = props.form
  const userInfo = useSelector(state => state.userInfo)

  const [detail, setDetail] = useState({})
  const [flow, setFlow] = useState([])
  // const [remark, setRemark] = useState([])
  // const [affixList, setAffixList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [pageId, setPageId] = useState('')
  const [auditRecord, setAuditRecord] = useState([])
  const [loading, setLoading] = useState(true)
  const [resPlaceholder, setResPlaceholder] = useState('请输入至少1个字符')
  // const [attList, setAttList] = useState([])
  // const [oldData, setOldData] = useState(null)
  // const [askType, setAskType] = useState('')
  const [files, setFiles] = useState([])
  const [projectDetail, setProjectDetail] = useState({})
  const [receiverValue, setReceiverValue] = useState('')
  //领取人列表
  const [receiveList, setReceiveList] = useState([])
  const [val, setVal] = useState('')
  const [staffNo, setStaffNo] = useState('')
  //验证是否检测完合同号
  const [checkContractNo, setCheckContractNo] = useState(false)
  //核销 已回 退回 归档
  const [contractVisible, setContractVisible] = useState(false)
  const [contractVal, setContractVal] = useState('')
  const [contractId, setContractId] = useState('')

  //操作权限
  const [auditAuth, setAuditAuth] = useState(false)//设置按钮组是否展示
  const [hasChargerPower, setHasChargerPower] = useState(false)//设置驳回和通过按钮是否展示
  const [replenish, setReplenish] = useState(false)//设置补充资料按钮是否展示
  const [returnUp, setReturnUp] = useState(false)//设置转上级按钮是否展示
  const [rejectMsgRequire, setRejectMsgRequire] = useState(false)//设置审批意见驳回必填
  const [ctrt_products, setCtrt_products] = useState({})
  const [fuzerens, setFuzerens] = useState([])
  const [ctrt_product_all, setCtrt_product_all] = useState({})
  const [ctrt_productsList, setCtrt_productsList] = useState([])
  const [saleServiceName, setSaleServiceName] = useState('')  //营销服务，项目名
  const [areaSale, setAreaSale] = useState('') //地区销售授权
  const [redBookData, setRedBookData] = useState({})

  useEffect(() => {
    let plaintext = parseSearch(location.search).sign
    let plaintextTwo = CryptoJS.MD5(parseSearch(location.search).id + 'ad629fddf8b8756d2e72e96ae035a5a4' + parseSearch(location.search).time).toString()

    if (plaintext == plaintextTwo && +new Date() - +parseSearch(location.search).time <= 21600000) {
      areaTxt = ''
      areaTxts = ''
      result = ''
      // console.log(parseSearch(location.search).new)
      if (location.search) {
        setPageId(parseSearch(location.search).id)
        getLeaveOutRow(parseSearch(location.search).id)
      } else {
        history.push({
          pathname: '/protocol/contract'
        })
      }
    } else {
      message.error('没有本页访问权限')
      setTimeout(() => {
        history.go(-2)
      }, 1000)
    }
  }, [])

  //获取详情
  const getLeaveOutRow = (id) => {
    api.getFortuneContractDetail({ id: id, type: 0 }).then(res => {
      // console.log(res)
      if (parseSearch(location.search).msgId) {
        api.setMessageReaded({ ids: [parseSearch(location.search).msgId] })
      }
      if (JSON.stringify(res) !== "[]") {
        let data = Object.assign({}, res)
        // console.log(data)
        if (data.status === 0) data.current = 0
        if (data.status === 1) data.current = 1
        if (data.status === 2) data.current = 2
        if (data.status === 3) data.current = 3
        // console.log(JSON.parse(data.productsDetail))
        data.files && setFiles(JSON.parse(data.files))
        if(data.templateId == 9) {
          const obj = JSON.parse(data.contractData)
          setCtrt_products(obj.ctrt_products[0])
          setFuzerens(obj.fuzerens)
        }
        if (data.templateId == 10 || data.templateId == 11) {
          const obj = JSON.parse(data.contractData)
          setCtrt_product_all(obj.ctrt_product_all)
        }
        if (data.templateId == 12 || data.templateId == 13) {
          const obj = JSON.parse(data.contractData)
          setSaleServiceName(obj.saleServiceName)
          setAreaSale(obj.areaSale)
          setCtrt_productsList(obj.ctrt_products)
        }
        if (data.templateId == 14) {
          const obj = JSON.parse(data.contractData)
          setRedBookData(obj.serviceInfo)
        }
        setProjectDetail(JSON.parse(data.productsDetail))
        // setProductDetail(productsDetail[1].product)
        setDetail(data)
        if (data.luruhetongFlag) {
          setReceiverValue(data.receiver)
          setStaffNo(data.staffNo)
          api.getContractStaffList({ userName: data.receiver }).then(res => {
            setReceiveList(res)
            // res.length > 0 && setStaffNo(res[0].staffNo)
          })
        }
        // data.checkInData ? setAttList([data.checkInData]) : setAttList([])
        let auditFlow = data.audit_flow
        auditFlow.forEach((item, index, self) => {
          if (item.persons && item.persons.length) {
            self[index].persons = item.persons.join('，')
          }
        })
        setFlow(auditFlow)
        // setAffixList(data.affix)
        setAuditRecord(data.audit_log)
        // if (data.note.length) {
        //   setRemark(data.note)
        // } else {
        //   setRemark('无')
        // }
        if (data.auditAuth && JSON.stringify(data.auditAuth) !== '[]') {
          setAuditAuth(true)
          setHasChargerPower(data.auditAuth.hasChargerPower)
          setReplenish(data.auditAuth.replenish)
          setReturnUp(data.auditAuth.return_up)
          setRejectMsgRequire(data.auditAuth.reject_msg_require)
        } else {
          setAuditAuth(false)
          setHasChargerPower(false)
          setReplenish(false)
          setReturnUp(false)
          setRejectMsgRequire(false)
        }
        // setOldData(data.oldData)
      } else {
        setDetail({})
        setFlow([])
        // setAffixList([])
        setAuditRecord([])
        // setOldData(null)
      }
      setLoading(false)
    })
  }
  //点击4个审批的按钮
  const leaveOutAudit = (no) => {
    areaTxt = ''
    result = no
    if (no == 0) {//eslint-disable-line
      if (detail.luruhetongFlag) {
        if (checkContractNo) {
          return message.warning('正在验证合同号的有效性，请稍候再点击提交。')
        }
        if (val == '') {
          return message.warning('请输入合同号或合同号已存在')
        }
        setResPlaceholder('请输入审批意见')
        setModalVisible(true)
      } else {
        setResPlaceholder('请输入审批意见')
        setModalVisible(true)
      }
     
    } else if (no == 1) {//eslint-disable-line
      //审批意见是否为必填项改由审批流控制 2020年2月22日 17:19:14
      setResPlaceholder('请输入审批意见')
      setModalVisible(true)
    } else {
      api.getFortuneContractAudit({
        id: pageId,
        type: result,
        remark: areaTxt
      }).then(res => {
        //todo 转上级或者补充资料 返回列表页
        let timer = setTimeout(() => {
          history.push({
            pathname: '/protocol/contract'
          })
          clearTimeout(timer)
        }, 1000)
      })
    }
  }
  const oprateHandleOk = () => {
    if (areaTxt.length < 1 && result == 1 && rejectMsgRequire) {//eslint-disable-line
      message.warning('请输入至少1个字符')
      return
    }
    let params = {
      id: pageId,
      type: result,
      remark: areaTxt,
      // contractNo: val ? val : '',
    }


    if (detail.luruhetongFlag && detail.isOld == 0 && result == 0 && val) {
      params.contractNo = val
    }
    // if(detail.luruhetongFlag && params['contractNo'] == '')
    // {
    //   return  message.warning('请填写合同号')
    // }
    // console.log('params=========', params)
    api.getFortuneContractAudit(params).then(res => {
      message.success('审批成功')
      setModalVisible(false)
      getLeaveOutRow(pageId)
    })
  }
  //取消弹窗
  const oprateHandleCancel = () => {
    setModalVisible(false)
  }
  //同意的样式
  const agreeNode = () => {
    return result === 0 ? <>
      通过 <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
    </> : result === 1 ? <>驳回 <Icon type="close-circle" theme="twoTone" twoToneColor="#eb2f96" /></> : 
    <>转上级</>
  }
  //审批意见
  const getAreaTxt = (e) => {
    areaTxt = e.target.value.trim()
  }

  //获取领取人
  const onChangeReceiver = (value, option) => {
    // console.log(value, option)
    if (Array.isArray(option.props.children)) {
      let arr = option.props.children[0]
      setReceiverValue(arr)
    } else {
      setReceiverValue(option.props.children)
    }
  }

  const geReceiveList = (value) => {
    let val = value.trim()
    if (val !== '') {
      if(receiveTimer){
        clearTimeout(receiveTimer)
      }
      receiveTimer = setTimeout(() => {
        receiveTimer = null
        clearTimeout(receiveTimer)
        api.getContractStaffList({ userName: val }).then(res => {
          setReceiveList(res)
        })
      }, 1000)
    }else{
      receiveTimer = setTimeout(() => {
        receiveTimer = null
        clearTimeout(receiveTimer)
        setReceiveList([])
      }, 2000)
    }
  }

  //获取合同号
  const onChangeContractNo = (e) => {
    setVal(e.target.value.trim())
    // console.log(e.target.value)
  }

  const onKeyUp = () => {
    if(val !== '') {
      setCheckContractNo(true)
      api.getFortuneContractCheckContractNo({contractNo: val}).then(res => {
        //console.log(res.constructor === Object)
        if (res.constructor === Object) {
          setVal('')
        }
        setCheckContractNo(false)
      }).catch(err => console.log(err))
    }
  }

  //修改领取人信息
  const onReceiver = () => {
    setLoading(true)
    if (!receiveList.length > 0) {
      setLoading(false)
      return message.warning('领取人不存在')
    }
    api.getFortuneContractAddReciverInfo({
      id: pageId,
      reciver: receiverValue
    }).then(res => {
      // console.log(res)
      message.success('修改成功')
      getLeaveOutRow(pageId)
      setLoading(false)
    }).catch(err => setLoading(false))
  }

  //核销 已回 退回 归档确认框
  const onContractOk = () => {
    ContractOperations(contractId)
  }

  //核销 已回 退回 归档的审批意见
  const getAreaTxts = (e) => {
    areaTxts = e.target.value.trim()
  }

  const ContractOperation = (index, val) => {
    areaTxts = ''
    setContractVisible(true)
    setContractVal(val)
    setContractId(index)
  }

  //合同操作
  const ContractOperations = (index) => {
    // console.log(index)
    setLoading(true)
    api.getFortuneContractOperate({
      id: pageId,
      type: index,
      remark: areaTxts
    }).then(res => {
      // console.log(res)
      setContractVisible(false)
      message.success('修改成功')
      getLeaveOutRow(pageId)
      setLoading(false)
    }).catch(error => setLoading(false))
  }

  //待领取
  const ContractOperationdailingqu = (index) => {
    setLoading(true)
    api.getFortuneContractOperate({
      id: pageId,
      type: index
    }).then(res => {
      // console.log(res)
      message.success('修改成功')
      getLeaveOutRow(pageId)
      setLoading(false)
    }).catch(error => setLoading(false))
  }

  //退回列表页
	const onGoBack = () => {
		history.push({pathname:'/protocol/contract',state: { goBack: 1}})
	}

  const redColumns = [
    {
      title: " ",
      dataIndex: "partyType",
      key: "partyType",
      width: "10%",
    }, {
      title: <><span style={{ color: "red" }}>*</span>负责人</>,
      dataIndex: "contactPerson",
      key: "contactPerson",
      align: 'center',
    }, {
      title: <><span style={{ color: "red" }}>*</span>联系电话</>,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: 'center',
    }, {
      title: <><span style={{ color: "red" }}>*</span>授权邮箱</>,
      dataIndex: "emailAddress",
      key: "emailAddress",
      align: 'center',
    }, {
      title: <><span style={{ color: "red" }}>*</span>授权邮箱后缀</>,
      dataIndex: "emailSuffix",
      key: "emailSuffix",
      align: 'center',
    }
  ]

  return (
    <>
      <Spin spinning={loading}>
        {
          parseSearch(location.search).new && parseSearch(location.search).new == 1 && <Button onClick={onGoBack} type="primary" style={{
            position: 'absolute',
            right: 0,
            top: '-20px'
          }}>返回上一页</Button>
        }
        <div className="contract-header" style={{padding: '20px 0'}}>
          <div>
            <div style={{ fontSize: '16px', marginBottom: '10px', color: '#000' }}>审批详情</div>
            <div style={{ fontSize: '12px', marginBottom: '5px', color: '#000' }}>
              合同业务编号：<span style={{ fontSize: '16px' }}>{detail.businessNo}</span>
            </div>
          </div>
          {
            detail.templateId == 6 || detail.businessType == 1 ? null : <form id="upload" method="get" action="/api/fortune/Contract/Preview" target="_blank">
            <input type="hidden" name="id" value={parseSearch(location.search).id} />
            <Button htmlType="submit">合同预览</Button>
          </form> 
          }
          
        </div>
        <div className="line"></div>
        <div className="step-wrap">
          <div className="contract-list-title">当前进度</div>
          <Steps current={detail.current}>
            {
              flow.map((item, index, self) => {
                let status = 'wait'
                if (item.time && item.time.length > 1) {
                  status = 'process'
                }
                return <Step status={status} title={item.nodeName} key={index} description={
                  <>
                    {item.persons && <div title={item.persons}>{item.persons}</div>}
                    <div title={item.statusName}>{item.statusName}</div>
                    <div title={item.time}>{item.time}</div>
                  </>
                } />
              })
            }
          </Steps>
        </div>
        <div className="line"></div>
        <Descriptions title="合同信息" layout="vertical" column={4} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
          <dItem label="合同主体">{detail.accountName}</dItem>
          {
            detail.templateId == 6 && <dItem label="类型">{detail.clientSource == 0 ? '直销' : '代理商'}</dItem>
          }
          {
            detail.templateId == 6 && <dItem label={detail.clientSource == 0 ? 'OAID' : '代理商名称'}>{detail.clientSource == 0 ? detail.customerId : detail.customerName}</dItem>
          }
          <dItem label="合同模板">{detail.templateTypeName}</dItem>
          {
            detail.businessTypeName ? <dItem label="业务类型">{detail.businessTypeName ? detail.businessTypeName : '无'}</dItem> : null
          }
          <dItem label="一式几份">{detail.copies}</dItem>
          <dItem label="领取人">
            {detail.luruhetongFlag ? <FormItem>
              {getFieldDecorator('receiveId', {
                initialValue: receiverValue + '' + ' ' + staffNo,
                rules: [{ required: true, message: '请输入领取人' }]
              })(<Select
                showSearch
                showArrow
                placeholder="请输入领取人"
                onSearch={geReceiveList}
                onChange={onChangeReceiver}
                notFoundContent='未搜索到相关领取人'
                filterOption={false}
                style={{ width: 180 }}
              >
                {receiveList.map((item, index) => <Option key={index} value={item.id}>{item.staffName} {item.staffNo}</Option>)}
              </Select>)}
              <Button style={{marginLeft: '20px'}} type="primary" size="small" onClick={onReceiver}>保存</Button>
            </FormItem> : <div>{detail.receiver}</div>} 
          </dItem>
          <dItem label="合同号">
            {detail.contractNo}
          </dItem>
          <dItem label="责任人">{detail.liablerName}</dItem>
          <dItem label="OAID/QID">{detail.clientSource == 0 ? detail.customerId : detail.channelQid}</dItem>
          {(detail.templateId == 12 || detail.templateId == 13) && <>
            <dItem label="代理期限">{detail.beginTime}至{detail.endTime}</dItem>
            <dItem label="营销服务">{saleServiceName}</dItem>
            <dItem label="地区销售授权">{areaSale}</dItem>
          </>}
          {(detail.templateId == 10 || detail.templateId == 11) && <dItem label="服务期限">{detail.beginTime}至{detail.endTime}</dItem>}
          {detail.templateId == 14  && <dItem label="服务期间">{detail.beginTime}至{detail.endTime}</dItem>}
        </Descriptions>
        <div className="line"></div>
        {(detail.templateId !== 9 && detail.templateId !== 12 && detail.templateId !== 13 && detail.templateId !== 14) && <Descriptions title="投放媒体" layout="horizontal" column={8} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
          <dItem label="">
            {
              projectDetail[0] && projectDetail[0].project ? projectDetail[0].project.map((v, i) => {
                return <div key={i}>{v.label}：</div>
              }) : null
            }
          </dItem>
          <dItem label="">
            {
              projectDetail[1] && projectDetail[1].product ? projectDetail[1].product.map((v, i) => {
                return <div key={i}>{v.label}</div>
              }) : null
            }
          </dItem>
          <dItem label=""></dItem>
          <dItem label=""></dItem>
          <dItem label=""></dItem>
          <dItem label=""></dItem>
          <dItem label=""></dItem>
          <dItem label=""></dItem>
        </Descriptions>}
        {(detail.templateId == 12 || detail.templateId == 13) && <>
          <div className="line"></div>
          <Descriptions title="推广服务内容" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
            <dItem label="">
              {
                ctrt_productsList.length > 0 ? ctrt_productsList.map((v, i) => {
                  return <div key={i} style={{ display: 'flex'}}>
                    <div style={{ width: '400px'}}><span style={{ fontWeight: '800',fontSize: '15px'}}>投放媒体：</span>{v.projectName}</div>
                    <div style={{ marginLeft: '20px'}}><span style={{ fontWeight: '800',fontSize: '15px'}}>返点：</span>{v.fandian}</div>
                  </div>
                }) : null
              }
            </dItem>
          </Descriptions>
        </>}
        {(detail.templateId == 10 || detail.templateId == 11) && <>
          <div className="line"></div>
          <Descriptions title="推广服务内容" layout="vertical" column={2} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
            <dItem label="推广费总计">{ctrt_product_all.receiptFeeAll}元整（大写：{ctrt_product_all.receiptFeeAllCapital}）</dItem>
            <dItem label="不含税价款">{ctrt_product_all.buhan_shui}元</dItem>
            <dItem label="增值税额">{ctrt_product_all.zengzhi_shui}元</dItem>
            <dItem label="税率">{ctrt_product_all.taxRate}%</dItem>
          </Descriptions>
        </>}
        {detail.templateId == 9 && <>
          <Descriptions title="推广服务内容" layout="vertical" column={2} className="order-detail-title" style={{ borderBottom: 'none', marginTop: '10px' }}>
            <dItem label="结算方式">{ctrt_products.settlementType}</dItem>
            <dItem label="服务费">{ctrt_products.settlementNum}</dItem>
          </Descriptions>
          {fuzerens.length > 0 && fuzerens.map((item, index) => {
            return <Descriptions title={<div style={{ fontSize: '14px'}}>委托账户及负责的人信息</div>} column={4} key={index} style={{ borderBottom: 'none', marginTop: '10px' }}>
              <dItem label="账户名称">{item.accountName}</dItem>
              <dItem label="公司名称">{item.companyName}</dItem>
              <dItem label="代运营媒体">{item.meiti}</dItem>
              <dItem label="账号ID">{item.accountNum}</dItem>
              <dItem label="姓名">{item.name}</dItem>
              <dItem label="联系电话">{item.tel}</dItem>
              <dItem label="微信号码">{item.weixin}</dItem>
              <dItem label="QQ号码">{item.qq}</dItem>
              <dItem label="电子邮箱">{item.email}</dItem>
              <dItem label="传真号码">{item.fax}</dItem>
            </Descriptions>
          })}
        </>}
        <div className="line"></div>
        <Descriptions title="签约信息" layout="vertical" column={2} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
          <dItem label="甲方">{detail.partA}</dItem>
          <dItem label="乙方">{detail.partB}</dItem>
        </Descriptions>

        {(detail.templateId == 14) && <>
          <div className="line"></div>
          <Descriptions title="服务内容" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
            <dItem label="">
              {
                <div style={{ display: 'flex' }}>
                  <div style={{ width: '400px' }}><span style={{ fontWeight: '800', fontSize: '14px' }}>媒体：{redBookData?.mediaName}</span></div>
                  <div style={{ marginLeft: '20px' }}><span style={{ fontWeight: '800', fontSize: '14px' }}>服务费用总金额(含税)：{redBookData?.totalServiceFeeInclusiveTax}</span></div>
                </div>
              }
            </dItem>
          </Descriptions>
          <Table
            dataSource={redBookData?.parties}
            columns={redColumns}
            pagination={false}
            bordered
            size="small"
            style={{ margin: '10px 0 20px' }}
          />
        </>}

        <div className="line"></div>
        <Descriptions title="其他信息" layout="horizontal" column={1} className="order-detail-title" style={{ marginTop: '10px', borderBottom: 'none' }}>
          <dItem label="附件">
            <>
              {
                files.length > 0 ? files.map((item, i) => {
                  return <div key={i}><a href={item.url} target="_blank" rel="noopener noreferrer">{item.name}</a></div>
                }) : <div>无</div>
              }
            </>
          </dItem>
          <dItem label="备注">{detail.note ? detail.note : '无'}</dItem>
        </Descriptions>
        <div className="line"></div>
        <div className="record-list" style={{ marginTop: '10px', marginBottom: '50px' }}>
          <Descriptions title="审批记录" layout="vertical" className="pro-detail-title" column={4} style={{ borderBottom: "none", marginBottom: "-30px" }}>
            <dItem label="姓名"></dItem>
            <dItem label="时间"></dItem>
            <dItem label="状态"></dItem>
            <dItem label="审批意见"></dItem>
          </Descriptions>
          {
            auditRecord.map((item, index) => {
              return <Descriptions layout="horizontal" className="pro-detail-title" column={4} key={index} style={{ borderBottom: "none", marginBottom: "-10px" }}>
                <dItem label="">{item.userInfo.staffName}</dItem>
                <dItem label="">{item.addTime}</dItem>
                <dItem label="">{item.status}</dItem>
                <dItem label="">{item.chargeMsg}</dItem>
              </Descriptions>
            })
          }
        </div>
        {/* <div className="line"></div> */}
      </Spin>
      <div className="contract-details-btn">
        <div className="contract-details-btns">
          <div className="contract-details-input">
              {
                detail.luruhetongFlag && detail.isOld == 0 && <div style={ {marginTop: '20px', marginBottom: '20px'}}>
                  合同号：<Input style={{ width: 120 }} onChange={onChangeContractNo} onKeyUp={onKeyUp}/>
                </div>
              }
          </div>
          {detail.guidangFlag && detail.isOld == 0 && <Button type="primary" onClick={() => ContractOperation(4, '归档')}>归档</Button>}
          {detail.hexiaoFlag && detail.isOld == 0 && <Button type="danger" style={{ marginLeft: '15px'}} onClick={() => ContractOperation(3, '核销')}>核销</Button>}
          {detail.tuihuiFlag && detail.isOld == 0 && <Button type="danger" style={{ marginLeft: '15px'}} onClick={() => ContractOperation(2, '退回')}>退回</Button>}
          {detail.yihuiFlag && detail.isOld == 0 && <Button type="primary" style={{ marginLeft: '15px'}} onClick={() => ContractOperation(1, '已回')}>已回</Button>}
          {detail.dailingqu && detail.isOld == 0 && <Popconfirm
            title={`确认领取人领取了合同 ${val !== '' ? val : detail.contractNo} 吗？`}
            onConfirm={debounce(() => {ContractOperationdailingqu(5)}, 3000)}
            okText="确认"
            cancelText="取消"
          >
            <Button type="primary" style={{ marginLeft: '15px'}}>标记已领取</Button>
          </Popconfirm>}
          <Modal
            title={`确认${contractVal}合同 ${val !== '' ? val : detail.contractNo} 吗？`}
            visible={contractVisible}
            onOk={onContractOk}
            onCancel={() => setContractVisible(false)}
            footer={[
              <Button key="back" onClick={() => setContractVisible(false)}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => {onContractOk()}, 3000)}>确定</Button>
            ]}
          >
            <div className="officework-modal-content">
              <span className="label">审批意见：</span>
              <Input.TextArea className="content-area" placeholder="请输入通过审批意见" onChange={getAreaTxts} rows={4} style={{ width: "100%" }} maxLength={100} />
            </div>
          </Modal>
          {auditAuth && detail.isOld == 0 && <div className="btns">
            {replenish && <Button onClick={debounce(() => {leaveOutAudit(3)}, 3000)} className="orange-btn">补充资料</Button>}
            {returnUp && <Button onClick={debounce(() => {leaveOutAudit(2)}, 3000)} className="orange-btn">转上级</Button>}
            {hasChargerPower && <Button onClick={() => leaveOutAudit(1)} style={{ marginLeft: '15px'}}>驳回</Button>}
            {hasChargerPower && <Button onClick={() => leaveOutAudit(0)} type="primary" style={{ marginLeft: '15px'}}>通过</Button>}
          </div>}
          <Modal
            title={agreeNode()}
            visible={modalVisible}
            onOk={debounce(() => {oprateHandleOk()}, 3000)}
            onCancel={oprateHandleCancel}
            destroyOnClose={true}
            footer={[
              <Button key="back" onClick={oprateHandleCancel}>取消</Button>,
              <Button key="submit" type="primary" onClick={debounce(() => {oprateHandleOk()}, 3000)}>
               确定
              </Button>]}
          >
            <div className="officework-modal-content">
              <span className="label">审批意见：</span><Input.TextArea className="content-area" placeholder={resPlaceholder} onChange={getAreaTxt} rows={4} style={{ width: "100%" }} maxLength={100} />
            </div>
          </Modal>
        </div>
      </div>
    </>
  )
}

export default Form.create()(Details)