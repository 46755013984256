/**
 * 模块名称: 补充信息
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
  message,
  Input,
  Form,
  Radio,
  DatePicker,
  Modal
} from 'antd'
import moment from 'moment'
import BtnGroup from '../../../components/BtnGroup'
import '../assets/explaine.scss'

const FormItem = Form.Item
const { RangePicker } = DatePicker

let replenish = {
  website: '',
  websitetype: '',
  openTypeCn: '',
  new_fee: '',
  rechargeAmount: '',
  receiptFee: '',
  serverTimeLimit: '',
  serverTimeLimitMin: '',
  serverTimeLimitMax: '',
  payTimeLimit: '',
  // remark: ''
}

const Explain = (props) => {
  const { savePreserve, onRelevance, cRef, isOld } = props
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form
  const [saveLoading, setSaveLoading] = useState(false)

  const [visibleRemark, setVisibleRemark] = useState(false)
  const [remark, setRemark] = useState('')
  const [payTimeLimit, setPayTimeLimit] = useState('')
  const [serverTimeLimit, setServerTimeLimit] = useState('')
  const [rechargeAmount, setRechargeAmount] = useState('')
  const [fee, setFee] = useState('')
  const [promotionObj, setPromotionObj] = useState('')
  const [disPromotion, setDisPromotion] = useState(false)
  const [disFee, setDisFee] = useState(false)
  const [disRechargeAmount, setDisRechargeAmount] = useState(false)
  const [disServerTimeLimit, setDisServerTimeLimit] = useState(false)
  const [disPayTimeLimit, setDisPayTimeLimit] = useState(false)
  const [disRemark, setDisRemark] = useState(false)
  const [feeVal, setFeeVal] = useState('')
  const [yearsVal, setYearsVal] = useState('')

  useEffect(() => {
    replenish = {
      website: '',
      websitetype: '',
      openTypeCn: '',
      new_fee: '',
      rechargeAmount: '',
      receiptFee: '',
      serverTimeLimit: '',
      serverTimeLimitMin: '',
      serverTimeLimitMax: '',
      payTimeLimit: '',
      // remark: ''
    }
  }, [])

  //清空补充信息
  const contentEmpty = () => {
    setFieldsValue({
      websitetype: '',
      openTypeCn: '',
      new_fee: '',
      rechargeAmount: '',
      receiptFee: '',
      serverTimeLimit: '',
      serverTimeLimitMin: '',
      serverTimeLimitMax: '',
      payTimeLimit: '',
      // remark: ''
    })
    setPromotionObj('')
    setFee('')
    setRechargeAmount('')
    setServerTimeLimit('')
    setPayTimeLimit('')
    setRemark('')
    setFeeVal('')
    setYearsVal('')
    setDisPromotion(false)
    setDisFee(false)
    setDisRechargeAmount(false)
    setDisServerTimeLimit(false)
    setDisPayTimeLimit(false)
    setDisRemark(false)
  }

  useImperativeHandle(cRef, () => ({
    // changeVal 就是暴露给父组件的方法
    changeVal: () => {
      contentEmpty()
    },
    changeAlter: (product) => {
      if (isOld == 1) {
        contentEmpty()
      } else {
        setFieldsValue({
          websitetype: product.websitetype,
          openTypeCn: product.openTypeCn,
          new_fee: product.new_fee == '无' ? product.new_fee : '有',
          rechargeAmount: product.rechargeAmount == '无' ? product.rechargeAmount : '有',
          receiptFee: product.receiptFee,
          serverTimeLimit: product.serverTimeLimit,
          payTimeLimit: product.payTimeLimit == '合同签订后三个工作日内' ? product.payTimeLimit : '支付日期',
          // remark: product.remark == '无' ? product.remark : '有'
        })
        setPromotionObj(product.website)
        // setYearsVal(product.new_fee_type)
        if (product.websitetype == '落地页' || product.websitetype == '视频') {
          setDisPromotion(true)
        } else {
          setDisPromotion(false)
        }
        if (product.new_fee == '无') {
          setDisFee(true)
          setFee('')
        }
        if (product.rechargeAmount == '无') {
          setRechargeAmount('')
          setDisRechargeAmount(true)
        }
        if (product.serverTimeLimit == '无') {
          setDisServerTimeLimit(true)
          setServerTimeLimit('')
        }
        if (product.payTimeLimit == '合同签订后三个工作日内') {
          setPayTimeLimit('')
          setDisPayTimeLimit(true)
        }
        // if (product.remark == '无') {
        //   setRemark('')
        //   setDisRemark(true)
        // }
        if (product.new_fee !== '无') {
          setDisFee(false)
          setFee(product.new_fee)
          setFeeVal('有')
        } else {
          setFeeVal('')
        }
        if (product.rechargeAmount !== '无') {
          setDisRechargeAmount(false)
          setRechargeAmount(product.rechargeAmount)
        }
        if (product.serverTimeLimit !== '无') {
          setDisServerTimeLimit(false)
          setServerTimeLimit([moment(product.serverTimeLimitMin), moment(product.serverTimeLimitMax)])
        }
        if (product.payTimeLimit !== '合同签订后三个工作日内') {
          setDisPayTimeLimit(false)
          setPayTimeLimit(moment(product.payTimeLimit))
        }
        // if (product.remark !== '无') {
        //   setDisRemark(false)
        //   setRemark(product.remark)
        // }
      }

    }
  }))

  //添加
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        // console.log(vals)
        if (vals.websitetype == '网站' && promotionObj == '') {
          return message.error('请输入推广对象')
        }
        if (vals.websitetype == '公众号' && promotionObj == '') {
          return message.error('请输入推广对象')
        }
        if (vals.websitetype == '其他' && promotionObj == '') {
          return message.error('请输入推广对象')
        }
        if (vals.new_fee == '有' && fee == '') {
          return message.error('请输入服务费')
        }
        // if (vals.new_fee == '有' && yearsVal == '') {
        //   return message.error('选择每年还是每次')
        // }
        if (vals.rechargeAmount == '有' && rechargeAmount == '') {
          return message.error('请输入账户充值金额')
        }
        if (vals.serverTimeLimit == '有' && serverTimeLimit == '') {
          return message.error('请选择推广服务期限')
        }
        if (vals.payTimeLimit == '支付日期' && payTimeLimit == '') {
          return message.error('请选择推广费用支付时间')
        }
        // if (vals.remark == '有' && remark == '') {
        //   return message.error('请输入备注内容')
        // } 

        replenish = {
          website: promotionObj ? promotionObj : '',
          websitetype: vals.websitetype ? vals.websitetype : '',
          openTypeCn: vals.openTypeCn ? vals.openTypeCn : '',
          new_fee: vals.new_fee == '有' ? fee : vals.new_fee,
          rechargeAmount: vals.rechargeAmount == '有' ? rechargeAmount : vals.rechargeAmount,
          receiptFee: vals.receiptFee ? vals.receiptFee : '',
          serverTimeLimit: vals.serverTimeLimit,
          serverTimeLimitMin: vals.serverTimeLimit == '有' ? moment(serverTimeLimit[0]).format('YYYY-MM-DD') : '',
          serverTimeLimitMax: vals.serverTimeLimit == '有' ? moment(serverTimeLimit[1]).format('YYYY-MM-DD') : '',
          payTimeLimit: vals.payTimeLimit == '支付日期' ? moment(payTimeLimit).format('YYYY-MM-DD') : vals.payTimeLimit,
          // remark: vals.remark == '有' ? remark : vals.remark,
          // new_fee_type: vals.new_fee == '有' ? yearsVal : ''
        }
        // console.log(replenish)
        // setSaveLoading(true)
           savePreserve(replenish)
        //   setSaveLoading(false)
        // if (vals.remark == '有') {
        //   setVisibleRemark(true)
        // } else {
        //   savePreserve(replenish)
        //   setSaveLoading(false)
        // }

      } else {
        message.error('请填写必填内容')
      }
    })
  }

  //备注提示
  const handleOkRemark = () => {
    setVisibleRemark(false)
    setTimeout(() => {
      savePreserve(replenish)
      setSaveLoading(false)
    }, 400)
  }

  const handleCancelRemark = () => {
    setSaveLoading(false)
    setVisibleRemark(false)
  }


  //备注
  const onRemark = (e) => {
    // console.log(e.target.value)
    setRemark(e.target.value)
  }

  //推广费用支付时间
  const onPayTimeLimit = (date) => {
    // console.log(moment(date).format('YYYY-MM-DD'))
    setPayTimeLimit(date)
  }

  //推广服务期限
  const onServerTimeLimit = (date) => {
    // console.log(moment(date).format('YYYY-MM-DD'))
    setServerTimeLimit(date)
  }

  //账户充值金额
  const onRechargeAmount = (e) => {
    // console.log(e.target.value)
    setRechargeAmount(e.target.value)
  }

  //服务费
  const onFee = (e) => {
    // console.log(e.target.value)
    setFee(e.target.value)
  }

  //甲方推广的对象
  const onPromotionObj = (e) => {
    // console.log(e.target.value)
    setPromotionObj(e.target.value)
  }

  //甲方推广对象发生变化时的回调
  const onWebsitetype = (e) => {
    // console.log(e.target.value)
    if (e.target.value == '落地页' || e.target.value == '视频') {
      setPromotionObj('')
      setDisPromotion(true)
    } else {
      setDisPromotion(false)
    }
  }

  //服务费变化回调
  const onIsFee = (e) => {
    // console.log(e.target.value)
    setFeeVal(e.target.value)
    if (e.target.value == '无') {
      setFee('')
      setDisFee(true)
    } else {
      setDisFee(false)
    }
  }

  //账户充值金额回调
  const onIsRechargeAmount = (e) => {
    // console.log(e.target.value)
    if (e.target.value == '无') {
      setRechargeAmount('')
      setDisRechargeAmount(true)
    } else {
      setDisRechargeAmount(false)
    }
  }

  //推广服务期限回调
  const onIsServerTimeLimit = (e) => {
    // console.log(e.target.value)
    if (e.target.value == '无') {
      setServerTimeLimit('')
      setDisServerTimeLimit(true)
    } else {
      setDisServerTimeLimit(false)
    }
  }

  //推广费用支付时间回调
  const onIsPayTimeLimit = (e) => {
    // console.log(e.target.value)
    if (e.target.value == '合同签订后三个工作日内') {
      setPayTimeLimit('')
      setDisPayTimeLimit(true)
    } else {
      setDisPayTimeLimit(false)
    }
  }

  //备注回调
  const onIsRemark = (e) => {
    //console.log(e.target.value)
    if (e.target.value == '无') {
      setRemark('')
      setDisRemark(true)
    } else {
      setDisRemark(false)
    }
  }

  //每年每次
  const onYears = (e) => {
    //console.log(e.target.value)
    // setYearsVal(e.target.value)
  }

  return (
    <>
      <Form onSubmit={handleSubmit} className="apply-form">
        <div className="apply-spin">
          <div className="apply-drawer">
            <FormItem label="甲方推广对象" className="apply-supplement">
              {getFieldDecorator('websitetype', {
                rules: [{ required: true, message: '请选择' }]
              })(<Radio.Group onChange={onWebsitetype}>
                <Radio value="网站">网站</Radio>
                <Radio value="公众号">公众号</Radio>
                <Radio value="落地页">落地页</Radio>
                <Radio value="视频">视频</Radio>
                <Radio value="其他">其他</Radio>
              </Radio.Group>)}
              <Input placeholder="请输入" onChange={onPromotionObj} value={promotionObj} disabled={disPromotion} />
            </FormItem>
            <FormItem label="新开/续费" className="apply-supplement">
              {getFieldDecorator('openTypeCn', {
                rules: [{ required: true, message: '请选择' }]
              })(<Radio.Group>
                <Radio value="new">新开</Radio>
                <Radio value="reNew">续费</Radio>
              </Radio.Group>)}
            </FormItem>
            <FormItem label="服务费" className="apply-supplement apply-service">
              {getFieldDecorator('new_fee', {
                rules: [{ required: true, message: '请选择' }]
              })(<Radio.Group onChange={onIsFee}>
                <Radio value="有">有</Radio>
                {/* {
                  feeVal == '有' && <Radio.Group onChange={onYears} value={yearsVal}>
                    <Radio value="年">每年</Radio>
                    <Radio value="次">每次</Radio>
                  </Radio.Group>
                } */}
                <Input placeholder="请输入" style={{ width: 100 }} onChange={onFee} value={fee} disabled={disFee} />元
                <Radio value="无" className="apply-service-input" >无</Radio>
                <span>（需审批至总监及项目负责人）</span>
              </Radio.Group>)}
            </FormItem>
            <FormItem label="账户充值金额" className="apply-supplement apply-service">
              {getFieldDecorator('rechargeAmount', {
                rules: [{ required: true, message: '请选择' }]
              })(<Radio.Group onChange={onIsRechargeAmount}>
                <Radio value="有">有</Radio>
                <Input
                  placeholder="请输入"
                  style={{ width: 100 }}
                  onChange={onRechargeAmount}
                  value={rechargeAmount}
                  disabled={disRechargeAmount}
                />元
                <Radio value="无" className="apply-service-input" >无</Radio>
              </Radio.Group>)}
            </FormItem>
            <FormItem label="推广费用总计（实收）">
              {getFieldDecorator('receiptFee', {
                rules: [{ required: true, message: '请选择' }]
              })(<Input placeholder="请输入" style={{ width: 100 }} />)}
              <span>元</span>
            </FormItem>
            <FormItem label="推广服务期限" className="apply-supplement apply-service">
              {getFieldDecorator('serverTimeLimit', {
                rules: [{ required: true, message: '请选择' }]
              })(<Radio.Group onChange={onIsServerTimeLimit}>
                <Radio value="有">有</Radio>
                <RangePicker
                  allowClear={false}
                  style={{ width: 240 }}
                  onChange={onServerTimeLimit}
                  value={serverTimeLimit ? serverTimeLimit : null}
                  disabled={disServerTimeLimit}
                />
                <Radio value="无" className="apply-service-input" >无</Radio>
              </Radio.Group>)}
            </FormItem>
            <FormItem label="推广费用支付时间">
              {getFieldDecorator('payTimeLimit', {
                rules: [{ required: true, message: '请选择' }]
              })(<Radio.Group onChange={onIsPayTimeLimit}>
                <Radio value="合同签订后三个工作日内">合同签订后三个工作日内</Radio>
                <Radio value="支付日期">支付日期</Radio>
              </Radio.Group>)}
              <DatePicker
                allowClear={false}
                style={{ width: 150 }}
                onChange={onPayTimeLimit}
                value={payTimeLimit ? payTimeLimit : null}
                disabled={disPayTimeLimit}
              />
            </FormItem>
            {/* <FormItem label="备注" className="apply-supplement apply-service">
              {getFieldDecorator('remark', {
                rules: [{ required: true, message: '请选择' }]
              })(<Radio.Group onChange={onIsRemark}>
                <Radio value="有">有</Radio>
                <Input placeholder="请输入" style={{ width: 100 }} onChange={onRemark} value={remark} disabled={disRemark} />
                <Radio value="无" className="apply-service-input" >无</Radio>
              </Radio.Group>)}
            </FormItem> */}
          </div>
          <div className="apply-button" >
            <BtnGroup onCancel={onRelevance} confirmName="保存" Loading={saveLoading} />
          </div>
        </div>
      </Form>
      <Modal
        title="信息"
        visible={visibleRemark}
        onOk={handleOkRemark}
        onCancel={handleCancelRemark}
      >
        <p>备注不为空时，该合同需要审批至副总或高级副总职位，是否继续保存？</p>
      </Modal>
    </>
  )
}

export default Form.create()(Explain)