import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Contract from './Contract'
import Templates from './Templates'
import Details from './Details'
import Apply from './Apply'
import ContractAmend from './ContractAmend'
import Explain from './Explain'
import Explains from './Explains'

const ContractIndex = ({ match, history }) => {

  return (
    <Switch>
      <Route path={match.path + '/templates'} component={Templates} />
      <Route path={match.path + '/details'} component={Details} />
      <Route path={match.path + '/apply'} component={Apply} />
      <Route path={match.path + '/contractAmend'} component={ContractAmend} />
      <Route path={match.path + '/explain'} component={Explain} />
      <Route path={match.path + '/explains'} component={Explains} />
      <Route component={Contract} />
    </Switch>
  )
}

export default ContractIndex