/**
 * 模块名称: 合同修改
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useRef } from 'react'
import BigNumber from 'bignumber.js'
import {
  Select,
  Input,
  DatePicker,
  InputNumber,
  Button,
  Form,
  message,
  Row,
  Col,
  Upload,
  Icon,
  Drawer,
  Spin,
  Tooltip,
  Radio,
  Modal,
  Checkbox,
  Table
} from 'antd'
import api from '../../../api'
import Img from './images/example.jpg'
import { parseSearch } from '../../../utils'
import { upLoadModule } from '../../../utils/common'
import moment from 'moment'
import Supplementary from './Explain'
import Supplementarys from './Explains'

const { Dragger } = Upload
const { Option } = Select
const FormItem = Form.Item
const { RangePicker } = DatePicker

let id = 1
let newObj = {}
let ctrt_product_all = {}
let ctrt_products = []
let productsDetail = []
let mediaKey = ''
let mediaVal = ''
let productKey = ''
let productVal = ''
let newIdArr = []
let mayArr = []
var temp
const invoiceList = [
  { id: 1, name: "专票" },
  { id: 2, name: "普票" },
]
const expenseList = [
  { id: 1, name: "策划服务费" },
  { id: 2, name: "设计服务费" },
  { id: 3, name: "咨询服务费" },
  { id: 4, name: "信息服务费" },
  { id: 5, name: "推广服务费" },
  { id: 6, name: "其他现代服务" }
]

const ContractAmend = (props) => {
  const productsRef = useRef()
  const { location, history } = props
  const { getFieldDecorator, setFieldsValue, validateFields, getFieldValue } = props.form
  getFieldDecorator('keys', { initialValue: [] })
  const keys = getFieldValue('keys')

  const [visible, setVisible] = useState(false)
  const [visibleInfo, setVisibleInfo] = useState(false)
  const draggerRef = useRef(null)
  const [fileList, setFileList] = useState([])
  const [formLoading, setformLoading] = useState(false)
  const [oaidName, setOaidName] = useState('')
  const [temptName, setTemptName] = useState([])//合同模板
  const [accountList, setAccountList] = useState([])//合同主体
  const [departList, setDepartList] = useState([])
  const [selectTemplate, setSelectTemplate] = useState('')//选择模板
  const [mediaList, setMediaList] = useState([])
  const [productList, setProductList] = useState([])
  const [accountObj, setAccountObj] = useState({})
  const [explain, setExplain] = useState('')//说明
  const [versionType, setVersionType] = useState([])
  const [versionKey, setVersionKey] = useState('')
  const [way, setWay] = useState(1)
  const [businessType, setBusinessType] = useState([])
  const [business, setBusiness] = useState('')
  const [receiptFee, setReceiptFee] = useState('')
  const [dx, setDX] = useState('')
  const [dxValue1, setDxValue1] = useState('')
  const [dxValue2, setDxValue2] = useState('')
  const [value1, setValue1] = useState('')
  const [bfvalue, setBfvalue] = useState('')
  const [value2, setValue2] = useState('')
  const [fdvalue, setFdvalue] = useState('')
  const [serviceAmount, setServiceAmount] = useState('')
  const [serviceAmountDx, setServiceAmountDx] = useState('')
  const [agent, setAgent] = useState('')
  const [detail, setDetail] = useState({})
  const [contractData, setContractData] = useState({})
  const [replenishId, setReplenishId] = useState('')
  const [productDetail, setProductDetail] = useState([])
  const [isShow, setIsShow] = useState(false)
  const [clientSourceType, setClientSourceType] = useState(0)
  const [contractNo, setContractNo] = useState(undefined)
  const [ids, setIds] = useState(undefined)
  const [businessStatus, setBusinessStatus] = useState([])
  const [projectList, setProjectList] = useState([]) //营销服务列表
  const [saleServiceList, setSaleServiceList] = useState([])
  const [taxRateVal, setTaxRateVal] = useState('6')//税率
  const [buhan_shui, setBuhan_shui] = useState('')
  const [zengzhi_shui, setZengzhi_shui] = useState('')
  const [dataRedSource, setDataRedSource] = useState([
    { key: "1", partyType: "甲方", contactPerson: "", phoneNumber: "", emailAddress: "", emailSuffix: "" },
    { key: "2", partyType: "乙方", contactPerson: "", phoneNumber: "", emailAddress: "", emailSuffix: "" },
  ])

  const search = parseSearch(location.search)

  useEffect(() => {
    id = 1
    ctrt_product_all = {}
    newObj = {}
    productsDetail = []
    ctrt_products = []
    newIdArr = []
    mayArr = []
    mediaKey = ''
    mediaVal = ''
    productKey = ''
    productVal = ''
    api.getFortuneContractApplyStatus().then(res => {
      const arr = res.ContractStatus.filter(v => v.id !== 3)
      setBusinessStatus(arr)
    })
  }, [])

  //输入合同号后调接口
  const modification = () => {
    api.getFortuneContractDetail({ type: 1, contractNo: contractNo, check: 1 }).then(res => {
      id = 1
      // console.log('编辑', res)
      setIds(res.id)
      setFieldsValue({ businessStatus: res.businessStatus })
      let datas = res
      api.getTopProjectList({ companyId: res.accountId, isOpen: 1 }).then(res => {
        setMediaList(res.list)
      })
      const contractData = JSON.parse(res.contractData)
      const productDetail = JSON.parse(res.productsDetail)
      // console.log(JSON.parse(res.contractData))
      if (contractData.area === undefined) contractData.area = ''
      if (contractData.cctt_id === undefined) contractData.cctt_id = ''
      if (contractData.cost === undefined) contractData.cost = ''
      if (contractData.costCapital === undefined) contractData.costCapital = ''
      if (contractData.ctrt_brhaccountid === undefined) contractData.ctrt_brhaccountid = ''
      if (contractData.ctrt_copies === undefined) contractData.ctrt_copies = ''
      if (contractData.ctrt_note === undefined) contractData.ctrt_note = ''
      if (contractData.ctrt_product_all === undefined) contractData.ctrt_product_all = {}
      if (contractData.ctrt_products === undefined) {
        contractData.ctrt_products = []
      } else {
        if (datas.isOld == 1 && contractData.ctrt_products.length > 0 && contractData.ctrt_products[0].id !== 0) {
          contractData.ctrt_products.map((v, ind) => {
            v.id = ind
          })
        }
      }
      if (contractData.endTime === undefined) contractData.endTime = ''
      if (contractData.pro_uid === undefined) contractData.pro_uid = ''
      if (contractData.services === undefined) {
        contractData.services = {
          businessService: "",
          serviceBuyType: "",
          serviceFee: "",
          serviceOrderAmount: "",
          serviceOrderAmountCapital: "",
          serviceQQ: "",
          serviceTimeLimit: "",
          serviceTimeLimitRemark: "",
        }
      }
      if (contractData.startTime === undefined) contractData.startTime = ''
      if (contractData.version === undefined) contractData.version = ''
      if (contractData.versionKey === undefined) contractData.versionKey = ''
      if (contractData.years === undefined) contractData.years = ''
      // console.log(contractData)
      setContractData(contractData)
      setProductDetail(productDetail)
      api.getFortuneContractAccountList({ showAll: 1 }).then(data => {
        const arr = data.filter((v, i) => {
          return v.id == datas.accountId
        })
        if (res.templateId == 12 || res.templateId == 13) {
          setProjectList(arr[0].projectList)
          const list = arr[0].projectList
          const newList = contractData.saleServiceId.split(',')
          const saleServiceList = list.filter(item1 => newList.some(item2 => item2 == item1.id))
          setSaleServiceList(saleServiceList)
        }
        // console.log(data, arr)
        arr.length > 0 && setAccountObj(arr[0])
      })
      setDetail(res)
      setSelectTemplate(res.templateId)
      setOaidName(res.customerName)
      setFieldsValue({
        customerId: res.templateId == 3 || res.templateId == 9 || res.templateId == 12 || res.templateId == 13 ? res.customerName : res.templateId == 6 && res.clientSource == 1 ? res.customerName : res.customerId,
        accountId: res.accountId,
        templateId: res.templateId,
        copies: res.copies,
        receiverId: res.receiverId,
        note: res.note
      })
      if (res.files.length !== 0) {
        let files = JSON.parse(res.files)
        if (files.length) {
          files.forEach((item, index) => {
            fileList.push({
              status: 'done',
              uid: index,
              name: item.name,
              url: item.url
            })
          })
          setFileList(files.map((item, index) => ({ ...item, uid: index })))
        }
      }


      if (res.businessType) setBusiness(res.businessType)
      if (res.templateId == 1) setFieldsValue({ businessType: res.businessType })
      if (res.templateId == 1 || res.templateId == 2 || res.templateId == 4) {
        setFieldsValue({
          media: {
            key: res.projectIds,
            label: res.projectNames
          }
        })
      }
      if (res.businessType == 2) {
        const time = contractData.services.serviceTimeLimit.split('~')
        setServiceAmount(contractData.services.serviceOrderAmount)
        setServiceAmountDx(contractData.services.serviceOrderAmountCapital)
        setFieldsValue({
          Acontacts: contractData.partA.contacts,
          Atel: contractData.partA.tel,
          Aemail: contractData.partA.email,
          Amobile: contractData.partA.mobile,
          Aaddress: contractData.partA.address,
          Azipcode: contractData.partA.zipcode,
          Afax: contractData.partA.fax,
          Bcontacts: contractData.partB.contacts,
          Btel: contractData.partB.tel,
          Bemail: contractData.partB.email,
          enterprise: contractData.services.serviceQQ,//企业号码
          serveYears: datas.isOld == 1 ? '' : [moment(time[0]), moment(time[1])], //服务年限
          serveYearsRemark: datas.isOld == 1 ? '' : contractData.services.serviceTimeLimitRemark,//服务年限备注
          businessService: datas.isOld == 1 ? '' : contractData.services.businessService,//业务服务
          moneyServe: contractData.services.serviceFee,//服务费金额
          purchase: datas.isOld == 1 ? '' : contractData.services.serviceBuyType.split('/')//购买类型
        })
      }
      if (res.templateId == 2) {
        setFieldsValue({
          version: contractData.version,
          years: contractData.years,
          cost: contractData.cost
        })
      }
      if (res.templateId == 3) {
        const keys = getFieldValue('keys')
        let nextKeys = []
        // console.log('6666666666=>', contractData)
        productDetail.length > 0 && productDetail[0].project.map((v, i, s) => {
          if (i == 0) setFieldsValue({ media: { key: v.key, label: v.label }})
        })
        contractData.ctrt_products.map((v, i) => {
          if (i == 0) {
            setFieldsValue({
              area: v.area,
              new_fee_type: v.new_fee_type,
              settlementNum1: v.settlementNum1,
              settlementType: v.settlementType
            })
          }
        })
        mayArr = contractData.ctrt_products
        let area = contractData.ctrt_products.map((v, i) => v.area)
        let new_fee_type = contractData.ctrt_products.map((v, i) => v.new_fee_type)
        let settlementNum1 = contractData.ctrt_products.map((v, i) => v.settlementNum1)
        let settlementType = contractData.ctrt_products.map((v, i) => v.settlementType)
        productDetail.length > 0 && productDetail[0].project.map((v, i, s) => {
          if (i !== 0) nextKeys.push(id++)
        })
        setFieldsValue({ keys: nextKeys })
        // console.log('productDetail[0].project', productDetail[0].project)
        setFieldsValue({
          medias: productDetail.length > 0 && productDetail[0].project,
          areas: area,
          new_fee_types: new_fee_type,
          settlementNum1s: settlementNum1,
          settlementTypes: settlementType,
          time: [!res.beginTime || res.beginTime == '0000-00-00' ? undefined : moment(res.beginTime), !res.endTime || res.endTime == '0000-00-00' ? undefined : moment(res.endTime)],
          Acontacts: contractData.partA.contacts,
          Atel: contractData.partA.tel,
          Aemail: contractData.partA.email,
          Bcontacts: contractData.partB.contacts,
          Btel: contractData.partB.tel,
          Bemail: contractData.partB.email,
          Baddress: contractData.partB.address,
          Bbank: contractData.partB.bank,
          Baccount: contractData.partB.account,
        })

      }
      if (res.templateId == 2 || res.templateId == 4 || res.templateId == 5 || res.templateId == 10 || res.templateId == 11  || res.templateId == 14) {
        setFieldsValue({
          Acontacts: contractData.partA.contacts,
          Atel: contractData.partA.tel,
          Aemail: contractData.partA.email,
          Aaddress: contractData.partA.address,
          Abank: contractData.partA.bank,
          Aaccount: contractData.partA.account,
          Bcontacts: contractData.partB.contacts,
          Btel: contractData.partB.tel,
          Bemail: contractData.partB.email
        })
      }
      if (res.templateId == 14) { // 小红书编辑
        const { mediaName, totalServiceFeeInclusiveTax, parties } = contractData.serviceInfo
        const { companyName, taxNumber, address, phone, bankAccount, invoiceType, expenseItem, bank } = contractData.invoiceInfo
        setFieldsValue({
          time: [moment(res.beginTime), moment(res.endTime)],
          mediaName,
          totalServiceFeeInclusiveTax,
          companyName,
          taxNumber,
          address,
          phone,
          bankAccount,
          invoiceType,
          expenseItem,
          bank,
        })
        const updatedDataSource = dataRedSource.map((item, index) => ({
          ...item,
          ...parties[index], // 合并数据
        }))
        setDataRedSource(updatedDataSource)
        const dataValues = {}
        updatedDataSource.forEach(item => {
          dataValues[`contactPerson_${item.key}`] = item.contactPerson
          dataValues[`phoneNumber_${item.key}`] = item.phoneNumber
          dataValues[`emailAddress_${item.key}`] = item.emailAddress
          dataValues[`emailSuffix_${item.key}`] = item.emailSuffix
        })
        setFieldsValue(dataValues)
      }
      if (res.templateId == 4) {
        setFieldsValue({ 
          time: [!res.beginTime || res.beginTime == '0000-00-00' ? undefined : moment(res.beginTime), !res.endTime || res.endTime == '0000-00-00' ? undefined : moment(res.endTime)]
        })
        setDxValue1(contractData.ctrt_product_all.putInAmountMinCapital)
        setDxValue2(contractData.ctrt_product_all.firstAmountCapital)
        setValue1(contractData.ctrt_product_all.putInAmountMin)
        setBfvalue(contractData.ctrt_product_all.firstAmountPercent)
        setValue2(contractData.ctrt_product_all.firstAmount)
        setFdvalue(contractData.ctrt_product_all.returnPercent)
        ctrt_product_all = {
          firstAmount: contractData.ctrt_product_all.firstAmount,
          firstAmountCapital: contractData.ctrt_product_all.firstAmountCapital,
          firstAmountPercent: contractData.ctrt_product_all.firstAmountPercent,
          putInAmountMin: contractData.ctrt_product_all.putInAmountMin,
          putInAmountMinCapital: contractData.ctrt_product_all.putInAmountMinCapital,
          returnPercent: contractData.ctrt_product_all.returnPercent
        }
      }
      if (res.templateId == 5 || res.templateId == 6 || res.templateId == 10 || res.templateId == 11) {
        const keys = getFieldValue('keys')
        let nextKeys = []
        // console.log(productDetail)
        if (productDetail.length > 0) {
          productDetail[0].project.map((v, i, s) => {
            if (i !== 0) nextKeys.push(id++)
          })
          // console.log(nextKeys)
          setFieldsValue({ keys: nextKeys })
          productDetail[0].project.map((v, i) => {
            if (i == 0) {
              setFieldsValue({
                media: {
                  key: v.key,
                  label: v.label
                }
              })
            }
          })
          productDetail[1].product.map((v, i) => {
            if (i == 0) {
              setFieldsValue({
                product: {
                  key: v.key,
                  label: v.label
                }
              })
            }
          })
          setFieldsValue({
            medias: productDetail[0].project,
            products: productDetail[1].product
          })
        }
      }
      if (res.templateId == 5 || res.templateId == 10 || res.templateId == 11) {
        ctrt_products = contractData.ctrt_products
        let sum = 0
        if (res.templateId == 5) {
          contractData.ctrt_products.forEach(item => {
            const num1 = new BigNumber(sum)
            const num2 = new BigNumber(item.receiptFee)
            sum = (num1.plus(num2)).toNumber()
          })
        }
        if (res.templateId == 10 || res.templateId == 11) {
          contractData.ctrt_products.forEach(item => {
            const num1 = new BigNumber(sum)
            const num2 = new BigNumber(item.shishou)
            sum = (num1.plus(num2)).toNumber()
          })
          setFieldsValue({ time: [moment(res.beginTime), moment(res.endTime)] }) 
        }
        setReceiptFee(sum)
        setDX(DX(sum))
        if ((res.templateId == 10 || res.templateId == 11) && contractData.ctrt_product_all.buhan_shui) {
          setBuhan_shui(contractData.ctrt_product_all.buhan_shui)
        } 
        if ((res.templateId == 10 || res.templateId == 11) && contractData.ctrt_product_all.zengzhi_shui) {
          setZengzhi_shui(contractData.ctrt_product_all.zengzhi_shui)
        }
        if (contractData.ctrt_product_all.taxRate) {
          setTaxRateVal(contractData.ctrt_product_all.taxRate)
          setFieldsValue({
            taxRate: contractData.ctrt_product_all.taxRate
          })
        }
      }
      if (res.templateId == 6) {
        setClientSourceType(res.clientSource)
      }
      if (res.templateId == 9) {
        const keys = getFieldValue('keys')
        let nextKeys = []
        const obj = contractData.ctrt_products[0]
        contractData.fuzerens.map((v, i) => {
          if (i == 0) {
            setFieldsValue({
              accountName: v.accountName,
              companyName: v.companyName,
              meiti: v.meiti,
              accountNum: v.accountNum,
              name: v.name,
              tel: v.tel,
              fax: v.fax,
              email: v.email,
              weixin: v.weixin,
              qq: v.qq
            })
          }
        })
        const accountNames = contractData.fuzerens.map((v, i) => v.accountName)
        const companyNames = contractData.fuzerens.map((v, i) => v.companyName)
        const meitis = contractData.fuzerens.map((v, i) => v.meiti)
        const accountNums = contractData.fuzerens.map((v, i) => v.accountNum)
        const names = contractData.fuzerens.map((v, i) => v.name)
        const tels = contractData.fuzerens.map((v, i) => v.tel)
        const faxs = contractData.fuzerens.map((v, i) => v.fax)
        const emails = contractData.fuzerens.map((v, i) => v.email)
        const weixins = contractData.fuzerens.map((v, i) => v.weixin)
        const qqs = contractData.fuzerens.map((v, i) => v.qq)
        contractData.fuzerens.map((v, i, s) => {
          if (i !== 0) nextKeys.push(id++)
        })
        setFieldsValue({ keys: nextKeys })
        setFieldsValue({ 
          settlementType: obj.settlementType,
          settlementNum: obj.settlementNum,
          time: [moment(res.beginTime), moment(res.endTime)],
          Acontacts: contractData.partA.contacts,
          Atel: contractData.partA.tel,
          Aemail: contractData.partA.email,
          Aaddress: contractData.partA.address,
          Abank: contractData.partA.bank,
          Aaccount: contractData.partA.account,
          Bcontacts: contractData.partB.contacts,
          Btel: contractData.partB.tel,
          Bemail: contractData.partB.email,
          accountNames,
          companyNames,
          meitis,
          accountNums,
          names,
          tels,
          faxs,
          emails,
          weixins,
          qqs
        })
      }
      if (res.templateId == 12 || res.templateId == 13) {
        const keys = getFieldValue('keys')
        let nextKeys = []
        // console.log('6666666666=>', contractData)
        productDetail.length > 0 && productDetail[0].project.map((v, i, s) => {
          if (i == 0) setFieldsValue({ media: { key: v.key, label: v.label }})
        })
        contractData.ctrt_products.map((v, i) => {
          if (i == 0) {
            setFieldsValue({
              settlementNum1: v.fandian,
            })
          }
        })
        mayArr = contractData.ctrt_products
        const settlementNum1 = contractData.ctrt_products.map((v, i) => v.fandian)
        const saleServiceName = contractData.saleServiceName.split(',')
        productDetail.length > 0 && productDetail[0].project.map((v, i, s) => {
          if (i !== 0) nextKeys.push(id++)
        })
        setFieldsValue({ keys: nextKeys })
        // console.log('productDetail[0].project', productDetail[0].project)
        setFieldsValue({
          medias: productDetail.length > 0 && productDetail[0].project,
          settlementNum1s: settlementNum1,
          saleServiceName: saleServiceName,
          areaSale: contractData.areaSale,
          time: [!res.beginTime || res.beginTime == '0000-00-00' ? undefined : moment(res.beginTime), !res.endTime || res.endTime == '0000-00-00' ? undefined : moment(res.endTime)],
          Acontacts: contractData.partA.contacts,
          Atel: contractData.partA.tel,
          Aemail: contractData.partA.email,
          Bcontacts: contractData.partB.contacts,
          Btel: contractData.partB.tel,
          Bemail: contractData.partB.email,
          Baddress: contractData.partB.address,
          Bbank: contractData.partB.bank,
          Baccount: contractData.partB.account,
        })
      }
      api.getFortuneContractDepartList({ limit: global.paramsLimit, userId: datas.receiverId  }).then(data => {
        if (data.some(v => v.id == datas.receiverId)) {
          setDepartList(data)
        } else {
          let newObj = { id: datas.receiverId, name: datas.receiver, type: '（已离职）' }
          data.push(newObj)
          setDepartList(data)
        }
        
      })
      if ((res.templateId == 1 && res.businessType == 1) || res.templateId == 5 || res.templateId == 6 || res.templateId == 2 || res.templateId == 7 || res.templateId == 8) {
        if(res.beginTime !== '0000-00-00') {
          setFieldsValue({ beginEndTime:  [moment(res.beginTime), moment(res.endTime)] })
        }
      }
    }).catch(err => {
      setFieldsValue({
        businessStatus: undefined,
        templateId: undefined,
        accountId: undefined,
        customerId: undefined,
        copies: undefined,
        receiverId: undefined,
        note: undefined
      })
      setDetail({})
      setSelectTemplate('')
      setFileList([])
    })
  }

  //获取公共接口
  const onCommonApply = () => {
    api.getFortuneContractAccountList({ showAll: 1 }).then(data => setAccountList(data))
    api.getFortuneContractTempStatus().then(data => {
      setTemptName(data.temptName.filter(v => v.id !== 8 && v.id !== 7))
      setVersionType(data.versionType)//可信百科
      setBusinessType(data.businessType)//qq模板业务类型
    })
    api.getFortuneContractExplain().then(res => setExplain(res.url))//说明
  }


  //添加
  const handleSubmit = (e, no) => {
    e.preventDefault()
    validateFields((err, vals) => {
      let uploadList = []
      for (let i = 0, k = fileList.length; i < k; i++) {
        if (fileList[i].status !== 'removed') {
          uploadList.push({
            url: fileList[i].url,
            name: fileList[i].name
          })
        }
      }
      if (!err) {
        const { keys, medias, products } = vals
        let partA = {}
        let partB = {}
        let conList = []
        let fuzerens = []
        let clientSource = ''
        if (selectTemplate == 1 && business == 1) {
          clientSource = '0'
          partA = {
            name: oaidName ? oaidName : JSON.stringify(detail) != '{}' ? detail.partA : ''
          }
          partB = {
            name: accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : ''
          }
        } else if (selectTemplate == 1 && business == 2) {
          clientSource = '0'
          partA = {
            name: oaidName ? oaidName : JSON.stringify(detail) != '{}' ? detail.partA : '',
            contacts: vals.Acontacts ? vals.Acontacts : '',
            tel: vals.Atel ? vals.Atel : '',
            email: vals.Aemail ? vals.Aemail : '',
            mobile: vals.Amobile ? vals.Amobile : '',
            address: vals.Aaddress ? vals.Aaddress : '',
            zipcode: vals.Azipcode ? vals.Azipcode : '',
            fax: vals.Afax ? vals.Afax : ''
          }
          partB = {
            name: accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : '',
            contacts: vals.Bcontacts ? vals.Bcontacts : '',
            tel: vals.Btel ? vals.Btel : '',
            email: vals.Bemail ? vals.Bemail : '',
            address: accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partB.address : '',
            zipcode: accountObj.zipcode || accountObj.zipcode == 0 || accountObj.zipcode == '' ? accountObj.zipcode : JSON.stringify(contractData) != '{}' ? contractData.partB.zipcode : '',
            fax: accountObj.fax || accountObj.fax == '' ? accountObj.fax : JSON.stringify(contractData) != '{}' ? contractData.partB.fax : '',
            bank: accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partB.bank : '',
            account: accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partB.account : '',
          }
        } else if (selectTemplate == 2 || selectTemplate == 4 || selectTemplate == 5 || selectTemplate == 10 || selectTemplate == 11 || selectTemplate == 14) {
          if (selectTemplate == 5 || selectTemplate == 10 || selectTemplate == 11) {
            //网络推广服务合同
            conList = [{
              projectId: vals.media.key,
              projectName: vals.media.label,
              productId: vals.product.key,
              productName: vals.product.label
            }]
            if (vals.medias) vals.medias.forEach((item, index, self) => {
              conList.push({ projectId: vals.medias[index].key, projectName: vals.medias[index].label, productId: vals.products[index].key, productName: vals.products[index].label })
            })
            conList = ctrt_products.map((item, index) => {
              return { ...item, ...conList[index] }
            })
            ctrt_product_all = {
              receiptFeeAll: receiptFee,
              receiptFeeAllCapital: dx
            }
            if (vals.taxRate) {
              ctrt_product_all.taxRate = vals.taxRate
            }
            if (selectTemplate == 10 || selectTemplate == 11) {
              ctrt_product_all.buhan_shui = buhan_shui
              ctrt_product_all.zengzhi_shui = zengzhi_shui
            }
          }
          clientSource = '0'
          partA = {
            name: oaidName ? oaidName : '',
            contacts: vals.Acontacts ? vals.Acontacts : '',
            tel: vals.Atel ? vals.Atel : '',
            email: vals.Aemail ? vals.Aemail : '',
            address: vals.Aaddress ? vals.Aaddress : '',
            bank: vals.Abank ? vals.Abank : '',
            account: vals.Aaccount ? vals.Aaccount : ''
          }
          partB = {
            name: accountObj.fullName ? accountObj.fullName : detail.partB,
            contacts: vals.Bcontacts ? vals.Bcontacts : '',
            tel: vals.Btel ? vals.Btel : '',
            email: vals.Bemail ? vals.Bemail : '',
            address: accountObj.address,
            bank: accountObj.bankName,
            account: accountObj.bankNo
          }
        } else if (selectTemplate == 3) {
          clientSource = '1'
          //渠道协议推广服务内容
          conList = [{
            projectId: vals.media.key,
            projectName: vals.media.label,
            area: vals.area,
            new_fee_type: vals.new_fee_type,
            settlementNum1: vals.settlementNum1,
            settlementNum2: vals.settlementType == 'zhe' ? vals.settlementNum1 * 10 : '',
            settlementType: vals.settlementType
          }]
          if (vals.medias) vals.medias.forEach((item, index, self) => {
            conList.push({
              projectId: vals.medias[index].key,
              projectName: vals.medias[index].label,
              new_fee_type: vals.new_fee_types[index],
              settlementNum1: vals.settlementNum1s[index],
              settlementNum2: vals.settlementTypes[index] == 'zhe' ? vals.settlementNum1s[index] * 10 : '',
              settlementType: vals.settlementTypes[index],
              area: vals.areas[index]
            })
          })

          partA = {
            name: accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partA : '',
            contacts: vals.Acontacts ? vals.Acontacts : '',
            tel: vals.Atel ? vals.Atel : '',
            email: vals.Aemail ? vals.Aemail : '',
            address: accountObj.address,
            bank: accountObj.bankName,
            account: accountObj.bankNo
          }
          partB = {
            name: agent ? agent : JSON.stringify(detail) != '{}' ? detail.partB : '',
            contacts: vals.Bcontacts ? vals.Bcontacts : '',
            tel: vals.Btel ? vals.Btel : '',
            email: vals.Bemail ? vals.Bemail : '',
            address: vals.Baddress ? vals.Baddress : '',
            bank: vals.Bbank ? vals.Bbank : '',
            account: vals.Baccount ? vals.Baccount : ''
          }
        } else if (selectTemplate == 6) {
          clientSource = vals.clientSource
          if (vals.clientSource == 1) {
            vals.customerId = '0'
            partA = {
              name: accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : ''
            }
            partB = {
              name: agent ? agent : JSON.stringify(detail) != '{}' ? detail.partB : ''
            }
          } else {
            partA = {
              name: oaidName
            }
            partB = {
              name: accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : ''
            }
          }
        } else if (selectTemplate == 9) {
          clientSource = '1'
          //渠道运营服务协议推广服务内容
          conList = [{
            settlementType: vals.settlementType,
            settlementNum: vals.settlementNum,
            settlementNumCn: DX(vals.settlementNum)
          }]
          fuzerens = [{
            accountName: vals.accountName,
            companyName: vals.companyName,
            meiti: vals.meiti,
            accountNum: vals.accountNum,
            name: vals.name,
            tel: vals.tel,
            fax: vals.fax,
            email: vals.email,
            weixin: vals.weixin,
            qq: vals.qq
          }]
          if (vals.accountNames) vals.accountNames.forEach((item, index, self) => {
            fuzerens.push({
              accountName: vals.accountNames[index],
              companyName: vals.companyNames[index],
              meiti: vals.meitis[index],
              accountNum: vals.accountNums[index],
              name: vals.names[index],
              tel: vals.tels[index],
              fax: vals.faxs[index],
              email: vals.emails[index],
              weixin: vals.weixins[index],
              qq: vals.qqs[index]
            })
          })

          partA = {
            name: agent ? agent : JSON.stringify(detail) != '{}' ? detail.partA : '',
            contacts: vals.Acontacts ? vals.Acontacts : '',
            tel: vals.Atel ? vals.Atel : '',
            email: vals.Aemail ? vals.Aemail : '',
            address: vals.Aaddress ? vals.Aaddress : '',
            bank: vals.Abank ? vals.Abank : '',
            account: vals.Aaccount ? vals.Aaccount : ''
          }
          partB = {
            name: accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : '',
            contacts: vals.Bcontacts ? vals.Bcontacts : '',
            tel: vals.Btel ? vals.Btel : '',
            email: vals.Bemail ? vals.Bemail : '',
            address: accountObj.address,
            bank: accountObj.bankName,
            account: accountObj.bankNo
          }
        } else if (selectTemplate == 12 || selectTemplate == 13) {
          console.log('saleServiceList===========', saleServiceList)
          clientSource = '1'
          //渠道协议推广服务内容
          conList = [{
            projectId: vals.media.key,
            projectName: vals.media.label,
            fandian: vals.settlementNum1
          }]
          if (vals.medias) vals.medias.forEach((item, index, self) => {
            conList.push({
              projectId: vals.medias[index].key,
              projectName: vals.medias[index].label,
              fandian: vals.settlementNum1s[index],
            })
          })
          partA = {
            name: accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partA : '',
            contacts: vals.Acontacts ? vals.Acontacts : '',
            tel: vals.Atel ? vals.Atel : '',
            email: vals.Aemail ? vals.Aemail : '',
            address: accountObj.address,
            bank: accountObj.bankName,
            account: accountObj.bankNo
          }
          partB = {
            name: agent ? agent : JSON.stringify(detail) != '{}' ? detail.partB : '',
            contacts: vals.Bcontacts ? vals.Bcontacts : '',
            tel: vals.Btel ? vals.Btel : '',
            email: vals.Bemail ? vals.Bemail : '',
            address: vals.Baddress ? vals.Baddress : '',
            bank: vals.Bbank ? vals.Bbank : '',
            account: vals.Baccount ? vals.Baccount : ''
          }
        }
        let values1
        if (medias || vals.media) {
          values1 = keys.map(key => medias[key])
          values1.unshift(vals.media)
          mediaKey = values1.map(v => v.key).join(',')
          mediaVal = values1.map(v => v.label).join(',')
          productsDetail = [{ project: values1 }]
        }
        if (vals.product) {
          let values2 = keys.map(key => products[key])
          values2.unshift(vals.product)
          productKey = values2.map(v => v.key).join(',')
          productVal = values2.map(v => v.label).join(',')
          productsDetail = [{ project: values1 }, { product: values2 }]
        }
        // console.log(selectTemplate, conList, values1)
        if ((selectTemplate == 5 || selectTemplate == 10 || selectTemplate == 11) && conList.length !== values1.length) {
          setIsShow(true)
          return message.error('请填写推广服务内容中的补充信息')
        }
        if (vals.time && !vals.time[0]) {
          return message.warning('请选择合同期限的开始日期')
        }
        if (vals.time && !vals.time[1]) {
          return message.warning('请选择合同期限的结束日期')
        }
        let obj = {
          customerId: selectTemplate == 3 || selectTemplate == 9 || selectTemplate == 12 || selectTemplate == 13 ? '0' : vals.customerId,
          customerName: selectTemplate == 3 || selectTemplate == 9 || selectTemplate == 12 || selectTemplate == 13 ? vals.customerId : selectTemplate == 6 && vals.clientSource == 1 ? agent ? agent : JSON.stringify(detail) != '{}' ? detail.partB : '' : oaidName,
          businessStatus: vals.businessStatus,
          clientSource: clientSource,
          accountId: vals.accountId,
          templateId: vals.templateId,
          copies: vals.copies,
          receiverId: vals.receiverId,
          partA: partA.name,
          partB: partB.name,
          projectIds: mediaKey ? mediaKey : '',
          projectNames: mediaVal ? mediaVal : '',
          productIds: productKey ? productKey : '',
          productNames: productVal ? productVal : '',
          productsDetail: JSON.stringify(productsDetail),
          beginTime: vals.time ? vals.time[0].format('YYYY-MM-DD') : '',
          endTime: vals.time ? vals.time[1].format('YYYY-MM-DD') : '',
          businessType: vals.businessType ? vals.businessType : '',
          note: vals.note,
          isPreview: no,
          files: fileList.length ? JSON.stringify(uploadList) : ''
        }
        if ((detail.templateId == 1 && detail.businessType == 1) || detail.templateId == 5 || detail.templateId == 6 || detail.templateId == 2 || detail.templateId == 7 || detail.templateId == 8) {
          if(detail.beginTime !== '0000-00-00') {
            obj.beginTime = vals.beginEndTime[0].format('YYYY-MM-DD')
            obj.endTime = vals.beginEndTime[1].format('YYYY-MM-DD')
          }
        }
        if (conList.length > 0) {
          conList = conList.map((v, i) => {
            return { ...v, id: i }
          })
        }
        // console.log(contractData)
        newObj = {
          ctrt_brhaccountid: obj.accountId,
          cctt_id: obj.templateId,
          ctrt_copies: obj.copies,
          pro_uid: obj.receiverId,
          ctrt_note: obj.note,
          area: vals.area ? vals.area : '',
          startTime: obj.beginTime,
          endTime: obj.endTime,
          areaSale: selectTemplate == 12 || selectTemplate == 13 ? vals.areaSale : '',
          saleServiceId: selectTemplate == 12 || selectTemplate == 13 ? saleServiceList.map(v => v.id).join(',') : '',
          saleServiceName: selectTemplate == 12 || selectTemplate == 13 ? saleServiceList.map(v => v.name).join(',') : '',
          ctrt_product_all: ctrt_product_all,
          ctrt_products: conList.length > 0 ? conList : [],
          partA: partA,
          partB: partB,
          services: {
            serviceBuyType: vals.purchase ? vals.purchase.join("/") : '',
            serviceQQ: vals.enterprise ? vals.enterprise : '',
            serviceTimeLimit: vals.serveYears ? moment(vals.serveYears[0]).format('YYYY-MM-DD') + '~' + moment(vals.serveYears[1]).format('YYYY-MM-DD') : '',
            serviceTimeLimitRemark: vals.serveYearsRemark ? vals.serveYearsRemark : '',
            serviceFee: vals.moneyServe ? vals.moneyServe : '',
            serviceOrderAmountCapital: serviceAmountDx ? serviceAmountDx : JSON.stringify(contractData) != '{}' ? contractData.services.serviceOrderAmountCapital : '',
            serviceOrderAmount: serviceAmount ? serviceAmount : JSON.stringify(contractData) != '{}' ? contractData.services.serviceOrderAmount : '',
            businessService: vals.businessService ? vals.businessService : ''//业务服务
          },
          serviceInfo: { // 小红书服务内容
            mediaName: vals.mediaName ? vals.mediaName : '',
            totalServiceFeeInclusiveTax: vals.totalServiceFeeInclusiveTax ? vals.totalServiceFeeInclusiveTax : '',
            parties: dataRedSource.map((row) => ({
              partyType: row.partyType,
              contactPerson: vals[`contactPerson_${row.key}`],
              phoneNumber: vals[`phoneNumber_${row.key}`],
              emailAddress: vals[`emailAddress_${row.key}`],
              emailSuffix: vals[`emailSuffix_${row.key}`],
            }))
          },
          invoiceInfo: { // 小红书甲方开票信息
            companyName: vals.companyName ? vals.companyName : '',
            taxNumber: vals.taxNumber ? vals.taxNumber : '',
            address: vals.address ? vals.address : '',
            phone: vals.phone ? vals.phone : '',
            bankAccount: vals.bankAccount ? vals.bankAccount : '',
            invoiceType: vals.invoiceType ? vals.invoiceType : '',
            expenseItem: vals.expenseItem ? vals.expenseItem : '',
            bank: vals.bank ? vals.bank : '',
          },
          versionKey: versionKey ? versionKey : '',
          version: vals.version || vals.version == 0 ? vals.version : '', //推广版本id
          years: vals.years ? vals.years : '', //年限
          cost: vals.cost ? vals.cost : '', //费用
          costCapital: vals.cost ? DX(vals.cost) : '', //费用大写
          fuzerens: fuzerens.length > 0 ? fuzerens : []
        }
        // console.log(obj, newObj)
        api.getFortuneContractApply({
          id: ids,
          ...obj,
          contractData: JSON.stringify(newObj),
          adminModify: 1
        }).then(data => {
          if (data.jumpUrl) {
            window.open(data.jumpUrl, "_blank")
          } else {
            history.push('/protocol/contract')
            ctrt_product_all = {}
            productsDetail = []
            newObj = {}
            ctrt_products = []
            newIdArr = []
            mayArr = []
            mediaKey = ''
            mediaVal = ''
            productKey = ''
            productVal = ''
          }
        }).catch(err => console.log(err))
      } else {
        setIsShow(true)
        message.error('请填写必填内容')
      }
    })
  }

  //取消返回列表页
  const handleReset = () => {
    history.goBack()
  }

  const uploadFiles = upLoadModule({
    fileList: fileList,
    setFileList: setFileList,
    draggerRef: draggerRef,
    accept: '.gif, .png, .jpg, .jpeg, .PDF, .zip',
    allowSizeType: 4,
    limitNum: 20
  })

  //补充信息
  const onMessage = (val) => {
    setVisible(true)
    setformLoading(true)
    setReplenishId(val)
    setTimeout(() => {
      if (productsRef.current) productsRef.current.changeVal()
      setformLoading(false)
    }, 600)
  }

  //修改信息
  const onModification = (k) => {
    setVisible(true)
    setformLoading(true)
    setReplenishId(k)
    let products = ctrt_products.filter(v => v.id == k)
    setTimeout(() => {
      productsRef.current && productsRef.current.changeAlter(products[0])
      setformLoading(false)
    }, 600)
  }

  const afterVisibleChange = (isOpen) => {
    if (isOpen) {

    }
  }

  //补充信息或修改信息组件内提交的内容
  const savePreserve = (vals) => {
    if (ctrt_products.some((v, i) => v.id == replenishId)) {
      ctrt_products.map((v, i) => {
        if (v.id == replenishId) ctrt_products[i] = { id: replenishId, ...vals }
      })
    } else {
      ctrt_products.push({ id: replenishId, ...vals })
      setIsShow(false)
    }
    let sum = 0
    if (selectTemplate == 5) {
      ctrt_products.forEach(item => {
        const num1 = new BigNumber(sum)
        const num2 = new BigNumber(item.receiptFee)
        sum = (num1.plus(num2)).toNumber()
      })
    }
    if (selectTemplate == 10 || selectTemplate == 11) {
      ctrt_products.forEach(item => {
        const num1 = new BigNumber(sum)
        const num2 = new BigNumber(item.shishou)
        sum = (num1.plus(num2)).toNumber()
      })
    }
    setReceiptFee(sum)
    let fee1 = (sum / (1 + (taxRateVal / 100))).toFixed(2)
    let fee2 = (sum - fee1).toFixed(2)
    setBuhan_shui(fee1)
    setZengzhi_shui(fee2)
    setDX(DX(sum))
    setVisible(false)
  }
  
  //税率发生变化
  const onChangeTaxRate = (e) => {
    const val = e.target.value
    setTaxRateVal(val)
    let fee1 = (receiptFee / (1 + (val / 100))).toFixed(2)
    let fee2 = (receiptFee - fee1).toFixed(2)
    setBuhan_shui(fee1)
    setZengzhi_shui(fee2)
  }

  const onRelevance = () => {
    setVisible(false)
  }

  //选择模板
  const onTemplate = (key) => {
    setClientSourceType(0)
    setBusiness('')
    setFieldsValue({
      accountId: undefined,
      customerId: search.clientId ? key == 3 || key == 9 || key == 12 || key == 13 ? '' : search.clientId : '',
      copies: '',
      receiverId: undefined,
      media: undefined,
      product: '',
      note: ''
    })
    if (key == 1) {
      setServiceAmount('')
      setServiceAmountDx('')
      setFieldsValue({
        businessType: '',
        Aname: '',
        Acontacts: '',
        Bcontacts: '',
        Atel: '',
        Btel: '',
        Aemail: '',
        Bemail: '',
        Amobile: '',
        Aaddress: '',
        Azipcode: '',
        Afax: '',
        purchase: '',
        enterprise: '',
        serveYears: '',
        businessService: '',
        flex: ''
      })
    } else if (key == 2) {
      setFieldsValue({
        version: '',
        years: '',
        cost: '',
        Acontacts: '',
        Bcontacts: '',
        Atel: '',
        Btel: '',
        Aemail: '',
        Bemail: '',
        Aaddress: '',
        Abank: '',
        Aaccount: '',
      })
    } else if (key == 14) {
      setFieldsValue({
        Acontacts: '',
        Bcontacts: '',
        Atel: '',
        Btel: '',
        Aemail: '',
        Bemail: '',
        Aaddress: '',
        // Abank: '',
        // Aaccount: '',
        mediaName: '',
        totalServiceFeeInclusiveTax: '',
        companyName: '',
        taxNumber: '',
        address: '',
        phone: '',
        bankAccount: '',
        invoiceType: '',
        expenseItem: '',
        bank: '',
        accountId: 24, // 主体固定为“北京太古”
      })
      onContractSubject(24)
    } else if (key == 3) {
      setFieldsValue({
        time: '',
        area: '',
        new_fee_type: '',
        settlementType: '',
        settlementNum1: '',
        areas: '',
        new_fee_types: '',
        settlementTypes: '',
        settlementNum1s: '',
        Acontacts: '',
        Bcontacts: '',
        Atel: '',
        Btel: '',
        Aemail: '',
        Bemail: '',
        Baddress: '',
        Bbank: '',
        Baccount: '',
      })
    } else if (key == 4) {
      setDxValue1('')
      setDxValue2('')
      setValue1('')
      setBfvalue('')
      setValue2('')
      setFdvalue('')
      setFieldsValue({
        time: '',
        Acontacts: '',
        Bcontacts: '',
        Atel: '',
        Btel: '',
        Aemail: '',
        Bemail: '',
        Aaddress: '',
        Abank: '',
        Aaccount: '',
      })
    } else if (key == 5) {
      setReceiptFee('')
      setDX('')
      setFieldsValue({
        medias: '',
        products: '',
        Acontacts: '',
        Bcontacts: '',
        Atel: '',
        Btel: '',
        Aemail: '',
        Bemail: '',
        Aaddress: '',
        Abank: '',
        Aaccount: ''
      })
    } else if (key == 6) {
      setFileList([])
      setFieldsValue({
        medias: '',
        products: ''
      })
    }
    setSelectTemplate(key)
    id = 1
    ctrt_product_all = {}
    newObj = {}
    newIdArr = []
    mayArr = []
    productsDetail = []
    ctrt_products = []
    mediaKey = ''
    mediaVal = ''
    productKey = ''
    productVal = ''
  }

  //检测OAID是否通过验证
  const onOAID = (e) => {
    let oaIdVal = e.target.value
    if (oaIdVal) {
      api.getFortuneSupplyAgreementCustomerInfo({ oaId: oaIdVal, type: selectTemplate == 3 || selectTemplate == 9 || selectTemplate == 12 || selectTemplate == 13 ? 1 : 0 }).then(res => {
        setOaidName(res.name)
        if (res.status !== 1) {
          Modal.confirm({
            title: '这是一条错误提示',
            content: '该OA客户还没有通过认证，不能进行任何操作，请尽快认证该OA客户',
            okText: '继续',
            cancelText: '取消',
          });
        }
      }).catch(err => {
        setFieldsValue({
          customerId: ''
        })
      })
    }
  }

  //输入代理商反现下面乙方代理商名称
  const onAgent = (e) => {
    let reg = /^[\u4e00-\u9fa5|a-zA-Z|（）|《》｜、]{1,100}$/
    if (e.target.value == '' || reg.test(e.target.value)) {
      setAgent(e.target.value)
    } else {
      setFieldsValue({
        customerId: undefined
      })
      setAgent('')
      message.warning('请输入汉字、字母、《》、中文括号或中文顿号组合的代理商名称')
    }
  }

  //选择主体
  const onContractSubject = (val) => {
    if (selectTemplate == 3 || selectTemplate == 5 || selectTemplate == 6 || selectTemplate == 10 || selectTemplate == 11) {
      setFieldsValue({
        media: undefined,
        product: undefined
      })
      if (keys.length > 0) {
        keys.forEach(v => {
          setFieldsValue({
            ["medias[" + (() => v)() + ']']: undefined,
            ["products[" + (() => v)() + ']']: undefined
          })
        })
      }
      if (selectTemplate == 10 || selectTemplate == 11) {
        ctrt_products = []
        setReceiptFee('')
        setDX('')
        setTaxRateVal('6')
        setBuhan_shui('')
        setZengzhi_shui('')
      }
    } else if(selectTemplate == 12 || selectTemplate == 13) {
      setFieldsValue({
        media: undefined,
        settlementNum1: undefined
      })
      if (keys.length > 0) {
        keys.forEach(v => {
          setFieldsValue({
            ["medias[" + (() => v)() + ']']: undefined,
            ["settlementNum1s[" + (() => v)() + ']']: undefined
          })
        })
      }
    } else {
      setFieldsValue({
        media: undefined
      })
    }
    const arr = accountList.filter((v, i) => {
      return v.id == val
    })
    setAccountObj(arr[0])
    if (selectTemplate == 12 || selectTemplate == 13) {
      setFieldsValue({
        saleServiceName: undefined
      })
      setProjectList(arr[0].projectList)
    }
    api.getTopProjectList({ companyId: val, isOpen: 1 }).then(res => setMediaList(res.list))
  }

  //推广版本
  const onVersion = (key) => {
    setVersionKey(key)
  }

  //结算方式
  const onWay = (e, k) => {
    setWay(e.target.value)
    if (mayArr.length > 0) {
      if (!(mayArr.some((v, i) => v.id == k))) {
        mayArr.push({
          id: k,
          settlementType: e.target.value
        })
      } else {
        mayArr.map((v, i) => {
          if (v.id == k) {
            v.settlementType = e.target.value
          }
        })
      }
    } else {
      mayArr.push({
        id: k,
        settlementType: e.target.value
      })
    }
  }

  //qq模板业务类型
  const onBusinessType = (key) => {
    setBusiness(key)
    if (key == 2) {
      setServiceAmount('')
      setServiceAmountDx('')
      setFieldsValue({
        note: '',
        Aname: '',
        Acontacts: '',
        Bcontacts: '',
        Atel: '',
        Btel: '',
        Aemail: '',
        Bemail: '',
        Amobile: '',
        Aaddress: '',
        Azipcode: '',
        Afax: '',
        purchase: '',
        enterprise: '',
        serveYears: '',
        businessService: '',
        flex: ''
      })
    }
  }

  //网络推广服务内容加
  const add = () => {
    const keys = getFieldValue('keys')
    const nextKeys = keys.concat(id++)
    setFieldsValue({
      keys: nextKeys,
    })
  }

  //删除
  const remove = (k) => {
    const keys = getFieldValue('keys')
    setFieldsValue({
      keys: keys.filter(key => key !== k),
    })
    //删除添加的项目
    if ((selectTemplate == 5 || selectTemplate == 10 || selectTemplate == 11) && ctrt_products.length) {
      ctrt_products = ctrt_products.filter((v) => v.id !== k)
    }
    let sum = 0
    if (selectTemplate == 5) {
      ctrt_products.forEach(item => {
        const num1 = new BigNumber(sum)
        const num2 = new BigNumber(item.receiptFee)
        sum = (num1.plus(num2)).toNumber()
      })
    }
    if (selectTemplate == 10 || selectTemplate == 11) {
      ctrt_products.forEach(item => {
        const num1 = new BigNumber(sum)
        const num2 = new BigNumber(item.shishou)
        sum = (num1.plus(num2)).toNumber()
      })
    }
    setReceiptFee(sum)
    let fee1 = (sum / (1 + (taxRateVal / 100))).toFixed(2)
    let fee2 = (sum - fee1).toFixed(2)
    setBuhan_shui(fee1)
    setZengzhi_shui(fee2)
    setDX(DX(sum))
    if (newIdArr.length > 0) {
      newIdArr = newIdArr.filter((v, i) => v.id !== k)
    }
  }

  //网络推广服务合同投放媒体
  const onSelectMedia = (value, k) => {
    if (newIdArr.length > 0) {
      if (newIdArr.some(v => v.id == k)) {
        newIdArr.map(item => {
          if (item.id == k) {
            item.name = value.key
          }
        })
      } else {
        newIdArr.push({
          name: value.key,
          id: k
        })
      }
    } else {
      newIdArr.push({
        name: value.key,
        id: k
      })
    }
    if (k == 0) {
      setFieldsValue({ product: undefined })
    } else {
      setFieldsValue({
        ["products[" + (() => k)() + ']']: undefined
      })
    }
  }

  const onSelectChangeProduct = (key) => {
    if (detail.isOld == 1) {
      ctrt_products = ctrt_products.filter((v, i) => v.id !== key)
    }
  }

  const onDropdownVisibleChangeProduct = (key) => {
    setProductList([])
    let arr = newIdArr.filter((v, i) => v.id == key)
    if (arr.length > 0) {
      api.getsysProjectChilds({ pid: arr[0].name }).then(res => {
        setProductList(res)
      })
    } else {
      message.warning('请先选择投放媒体')
    }
  }


  //转大写
  const DX = (val) => {
    let tranvalue = val + ''
    var str = ""
    if (tranvalue == 0) {
      str = '零元整'
      return str
    }
    try {
      var i = 1;
      var dw2 = new Array("", "万", "亿");//大单位
      var dw1 = new Array("拾", "佰", "仟");//小单位
      var dw = new Array("零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖");//整数部分用
      //以下是小写转换成大写显示在合计大写的文本框中     
      //分离整数与小数
      var source = splits(tranvalue);
      var num = source[0];
      var dig = source[1];

      //转换整数部分
      var k1 = 0;//计小单位
      var k2 = 0;//计大单位
      var sum = 0;
      var len = source[0].length;//整数的长度
      for (i = 1; i <= len; i++) {
        var n = source[0].charAt(len - i);//取得某个位数上的数字
        var bn = 0;
        if (len - i - 1 >= 0) {
          bn = source[0].charAt(len - i - 1);//取得某个位数前一位上的数字
        }
        sum = sum + Number(n);
        if (sum != 0) {
          str = dw[Number(n)].concat(str);//取得该数字对应的大写数字，并插入到str字符串的前面
          if (n == '0') sum = 0;
        }
        if (len - i - 1 >= 0) {//在数字范围内
          if (k1 != 3) {//加小单位
            if (bn != 0) {
              str = dw1[k1].concat(str);
            }
            k1++;
          } else {//不加小单位，加大单位
            k1 = 0;
            temp = str.charAt(0);
            if (temp == "万" || temp == "亿")//若大单位前没有数字则舍去大单位
              str = str.substr(1, str.length - 1);
            str = dw2[k2].concat(str);
            sum = 0;
          }
        }
        if (k1 == 3)//小单位到千则大单位进一
        { k2++; }
      }

      //转换小数部分
      var strdig = "";
      if (dig != "") {
        var n = dig.charAt(0);
        if (n != 0) {
          strdig += dw[Number(n)] + "角";//加数字
        }
        var n = dig.charAt(1);
        if (n != 0) {
          strdig += dw[Number(n)] + "分";//加数字
        }
      }
      if (strdig !== '') {
        // console.log(str)
        if (str !== '') {
          str += "元" + strdig;
        } else {
          str += strdig;
        }

      } else {
        str += "元" + strdig + '整'
      }

    } catch (e) {
      return "0元";
    }
    return str;
  }

  //拆分整数与小数
  function splits(tranvalue) {
    var value = new Array('', '');
    temp = tranvalue.split(".");
    for (var i = 0; i < temp.length; i++) {
      value[i] = temp[i];
    }
    return value;
  }

  const onChange1 = (e) => {
    setValue1(e.target.value)
    setDxValue1(DX(e.target.value))
    ctrt_product_all.putInAmountMin = e.target.value
    ctrt_product_all.putInAmountMinCapital = DX(e.target.value)
  }

  const onChange2 = (e) => {
    setBfvalue(e.target.value)
    ctrt_product_all.firstAmountPercent = e.target.value
  }

  const onChange3 = (e) => {
    setValue2(e.target.value)
    setDxValue2(DX(e.target.value))
    ctrt_product_all.firstAmount = e.target.value
    ctrt_product_all.firstAmountCapital = DX(e.target.value)
  }

  const onChange4 = (e) => {
    setFdvalue(e.target.value)
    ctrt_product_all.returnPercent = e.target.value
  }

  //qq模板
  const onServiceAmount = (e) => {
    setServiceAmount(e.target.value)
    setServiceAmountDx(DX(e.target.value))
  }

  //结算政策
  const onSettlementNum = (value, k) => {
    mayArr.map((v, i) => {
      if (v.id == k) {
        if (v.settlementType == 'zhe') {
          v.settlementNum2 = value * 10
          v.settlementNum1 = ''
        } else {
          v.settlementNum2 = ''
          v.settlementNum1 = value
        }
      }
    })
  }

  //补充信息示列
  const onInfo = () => {
    setVisibleInfo(true)
  }

  //不会填写提示
  const handleCancelInfo = () => {
    setVisibleInfo(false)
  }

  //选择直销代理商
  const onChangeCustomerType = (e) => {
    setClientSourceType(e.target.value)
  }

  //获取合同号
  const onChangeContractNo = (e) => {
    let val = e.target.value.trim()
    setContractNo(val)
  }

  //判断合同号是否存在
  const onBlurContractNo = () => {
    if (!contractNo) {
      return message.warning('请输入合同号')
    }
    onCommonApply()
    modification()
  }

  //选中营销服务
  const onSelectSaleService = (val, Option) => {
    if (saleServiceList.length > 0) {
      saleServiceList.push({
        id: Option.key,
        name: val
      })
      setSaleServiceList(JSON.parse(JSON.stringify(saleServiceList)))
    } else {
      setSaleServiceList([{
        id: Option.key,
        name: val
      }])
    }
    
  }

  //取消选中营销服务
  const onDeselectSaleService = (val) => {
    const arr = saleServiceList.filter(v => v.name != val)
    setSaleServiceList(arr)
  }

  const redColumns = [
    {
      title: " ",
      dataIndex: "partyType",
      key: "partyType",
      width: "10%",
    }, {
      title: <><span style={{ color: "red" }}>*</span>负责人</>,
      dataIndex: "contactPerson",
      key: "contactPerson",
      align: 'center',
      render: (_, record) => (
        <FormItem style={{ margin: 0 }}>
          {getFieldDecorator(`contactPerson_${record.key}`, {
            rules: [{ required: true, message: '请输入负责人' }]
          })(<Input placeholder="请输入" />)}
        </FormItem>
      ),
    }, {
      title: <><span style={{ color: "red" }}>*</span>联系电话</>,
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: 'center',
      render: (_, record) => (
        <FormItem style={{ margin: 0 }}>
          {getFieldDecorator(`phoneNumber_${record.key}`, {
            rules: [{ required: true, message: '请输入联系电话' }]
          })(<Input placeholder="请输入" />)}
        </FormItem>
      ),
    }, {
      title: <><span style={{ color: "red" }}>*</span>授权邮箱</>,
      dataIndex: "emailAddress",
      key: "emailAddress",
      align: 'center',
      render: (_, record) => (
        <FormItem style={{ margin: 0 }}>
          {getFieldDecorator(`emailAddress_${record.key}`, {
            rules: [{ required: true, message: '请输入授权邮箱' }]
          })(<Input placeholder="请输入" />)}
        </FormItem>
      ),
    }, {
      title: <><span style={{ color: "red" }}>*</span>授权邮箱后缀</>,
      dataIndex: "emailSuffix",
      key: "emailSuffix",
      align: 'center',
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 5 }}>@</span>
          <FormItem style={{ margin: 0 }}>
            {getFieldDecorator(`emailSuffix_${record.key}`, {
              rules: [{ required: true, message: '请输入授权邮箱后缀' }]
            })(<Input placeholder="请输入" />)}
          </FormItem>
        </div>
      ),
    }
  ]

  return (
    <>
      <div className="apply-warp">
        <h1 className="apply-title">合同修改{detail.templateTypeName && '-' + detail.templateTypeName}</h1>
        <div className="line"></div>
        <Form onSubmit={handleSubmit} className="apply-form">
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="合同号" className="apply-FormItem">
                {getFieldDecorator('contractNo', {
                  rules: [{ required: true, message: '请输入' }]
                })(<Input placeholder="请输入" style={{ width: 240 }} onChange={onChangeContractNo} onBlur={onBlurContractNo} />)}
              </FormItem>
            </Col>
            <Col span={6}>
              <FormItem label="合同状态" className="apply-FormItem">
                {getFieldDecorator('businessStatus', {
                  rules: [{ required: true, message: '请输入' }]
                })(<Select placeholder="请选择" style={{ width: 240 }} >
                  {
                    businessStatus.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            {
              (detail.templateId == 1 && detail.businessType == 1) ||
                detail.templateId == 5 || detail.templateId == 6 || detail.templateId == 2 || detail.templateId == 7 || detail.templateId == 8 ?
                detail.beginTime !== '0000-00-00' ?
                  <Col span={6}>
                    <FormItem label="合同期限" className="apply-FormItem">
                      {getFieldDecorator('beginEndTime', {
                        rules: [{ required: true, message: '请输入' }]
                      })(<RangePicker allowClear={false} style={{ width: 240 }} />)}
                    </FormItem>
                  </Col> : null : null
            }
          </Row>
          <h3 className="apply-tit">客户信息</h3>
          <div className="underline"></div>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="合同模板" className="apply-FormItem">
                {getFieldDecorator('templateId', {
                  rules: [{ required: true, message: '请选择' }]
                })(<Select placeholder="请选择" disabled style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={onTemplate}>
                  {
                    temptName.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
                <div className="apply-abs">
                  <Tooltip title={<a href={explain} target='_blank' ><span style={{ color: '#fff' }}>说明</span></a>}>
                    <Icon type="info-circle" />
                  </Tooltip>
                </div>
              </FormItem>
            </Col>
            {
              selectTemplate == 6 && <Col span={6}>
                <FormItem label="类型" className="apply-FormItem apply-required">
                  {getFieldDecorator('clientSource', {
                    initialValue: clientSourceType,
                    rules: [{ required: true, message: '请选择' }]
                  })(<Radio.Group onChange={onChangeCustomerType}>
                    <Radio value={0}>直销</Radio>
                    <Radio value={1}>代理商</Radio>
                  </Radio.Group>)}
                </FormItem>
              </Col>
            }
            <Col span={6}>
              <FormItem label="合同主体简称" className="apply-FormItem apply-required">
                {getFieldDecorator('accountId', {
                  rules: [{ required: true, message: '请选择' }]
                })(<Select
                  showSearch={true} 
                  optionFilterProp="children"
                  placeholder="请选择"
                  style={{ width: 240 }}
                  dropdownMatchSelectWidth={false}
                  onSelect={onContractSubject}
                  disabled={selectTemplate == 14 ? true : false}
                // onDropdownVisibleChange={bool => {
                //   bool && api.getFortuneContractAccountList().then(data => {
                //     setAccountList(data)
                //   })
                // }}
                >
                  {
                    accountList.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            <Col span={6}>
              {
                selectTemplate == 3 || selectTemplate == 9 || selectTemplate == 12 || selectTemplate == 13 ?
                  <FormItem label="代理商名称" className="apply-FormItem apply-required">
                    {getFieldDecorator('customerId', {
                      rules: [{ required: true, message: '请输入' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} onBlur={onAgent} maxLength={100} />)}
                  </FormItem> :
                  clientSourceType == 0 ?
                    <FormItem label="OAID" className="apply-FormItem apply-required">
                      {getFieldDecorator('customerId', {
                        rules: [{ required: true, message: '请输入' }]
                      })(<Input placeholder="请输入" style={{ width: 240 }} onBlur={onOAID} maxLength={100} />)}
                    </FormItem> : <FormItem label="代理商名称" className="apply-FormItem apply-required">
                      {getFieldDecorator('customerId', {
                        rules: [{ required: true, message: '请输入' }]
                      })(<Input placeholder="请输入" style={{ width: 240 }} onBlur={onAgent} maxLength={100} />)}
                    </FormItem>
              }
            </Col>
            {
              selectTemplate != 6 && <Col span={6}>
                <FormItem label="一式几份" className="apply-FormItem">
                  {getFieldDecorator('copies', {
                    rules: [{ required: true, message: '请选择' }]
                  })(<InputNumber min={1} max={10} placeholder="请选择" style={{ width: 240 }} />)}
                </FormItem>
              </Col>
            }
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <FormItem label="合同领取人" className="apply-FormItem">
                {getFieldDecorator('receiverId', {
                  rules: [{ required: true, message: '请选择' }]
                })(<Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="请选择"
                  style={{ width: 240 }}
                  dropdownMatchSelectWidth={false}
                >
                  {
                    departList.map((v, i) => {
                      return <Option value={v.id} key={i}>{v.name} {v.type ? v.type : ''}</Option>
                    })
                  }
                </Select>)}
              </FormItem>
            </Col>
            {
              (selectTemplate == 12 || selectTemplate == 13) && <>
                <Col span={6}>
                  <FormItem label="代理期限" className="apply-FormItem">
                    {getFieldDecorator('time', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<RangePicker allowClear={false} style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="营销服务" className="apply-FormItem">
                    {getFieldDecorator('saleServiceName', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select
                      mode="multiple"
                      placeholder="请选择"
                      style={{ width: 240 }}
                      showSearch
                      onSelect={onSelectSaleService}
                      onDeselect={onDeselectSaleService}
                      // dropdownMatchSelectWidth={false}
                    >
                      {
                        projectList.map((v, i) => {
                          return <Option value={v.name} key={v.id}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="地区销售授权" className="apply-FormItem">
                    {getFieldDecorator('areaSale', {
                      rules: [{ required: true, message: '请输入' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }}/>)}
                  </FormItem>
                </Col>
              </>
            }
            {
              selectTemplate == 6 && <Col span={6}>
                <FormItem label="一式几份" className="apply-FormItem">
                  {getFieldDecorator('copies', {
                    rules: [{ required: true, message: '请选择' }]
                  })(<InputNumber min={1} max={10} placeholder="请选择" style={{ width: 240 }} />)}
                </FormItem>
              </Col>
            }
            {
              selectTemplate == 3 || selectTemplate == 4 || selectTemplate == 9 || selectTemplate == 10 || selectTemplate == 11 ?
                <Col span={6}>
                  <FormItem label="合同期限" className="apply-FormItem">
                    {getFieldDecorator('time', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<RangePicker allowClear={false} style={{ width: 240 }} />)}
                  </FormItem>
                </Col> : selectTemplate == 1 ? <>
                  <Col span={6}>
                    <FormItem label="业务类型" className="apply-FormItem">
                      {getFieldDecorator('businessType', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Select placeholder="请选择" style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={onBusinessType}>
                        {
                          businessType.map((v, i) => {
                            return <Option value={v.id} key={i}>{v.name}</Option>
                          })
                        }
                      </Select>)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="投放媒体" className="apply-FormItem">
                      {getFieldDecorator('media', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={onSelectMedia}>
                        {
                          mediaList.map((v, i) => {
                            return <Option value={v.id} key={i}>{v.name}</Option>
                          })
                        }
                      </Select>)}
                    </FormItem>
                  </Col>
                </> : selectTemplate == 2 ? <Col span={6}>
                  <FormItem label="投放媒体" className="apply-FormItem">
                    {getFieldDecorator('media', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={onSelectMedia}>
                      {
                        mediaList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col> : null
            }
            {
              selectTemplate == 14 &&
              <Col span={6}>
                <FormItem label="服务期间" className="apply-FormItem">
                  {getFieldDecorator('time', {
                    rules: [{ required: true, message: '请选择' }]
                  })(<RangePicker allowClear={false} style={{ width: 240 }} />)}
                </FormItem>
              </Col>
            }
          </Row>
          {
            // 小红书信息
            selectTemplate == 14 &&
            <>
              <div className="line"></div>
              <h3 className="apply-tit">双方公司信息及联系方式</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="甲方" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    <div>{oaidName ? oaidName : detail.partA}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    <div>{accountObj.fullName ? accountObj.fullName : detail.partB}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="地址" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('Aaddress', {
                      rules: [{ required: true, message: '请输入甲方联系地址' }]
                    })(<Input placeholder="请输入甲方联系地址" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="地址" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    <div>{accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partB.address : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系人" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('Acontacts', {
                      rules: [{ required: true, message: '请输入甲方联系人姓名' }]
                    })(<Input placeholder="请输入甲方联系人姓名" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系人" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('Bcontacts', {
                      rules: [{ required: true, message: '请输入乙方联系人姓名' }]
                    })(<Input placeholder="请输入乙方联系人姓名" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电话" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('Atel', {
                      rules: [{ required: true, message: '请输入甲方联系电话' }]
                    })(<Input placeholder="请输入甲方联系电话" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="电话" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('Btel', {
                      rules: [{ required: true, message: '请输入乙方联系电话' }]
                    })(<Input placeholder="请输入乙方联系电话" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="邮箱" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('Aemail', {
                      rules: [{ required: true, message: '请输入甲方电子邮箱' }]
                    })(<Input placeholder="请输入甲方电子邮箱" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="邮箱" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('Bemail', {
                      rules: [{ required: true, message: '请输入乙方电子邮箱' }]
                    })(<Input placeholder="请输入乙方电子邮箱" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    <div>{accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partB.bank : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    <div>{accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partB.account : ''}</div>
                  </FormItem>
                </Col>
              </Row>
            </>
          }
          {
            // 小红书服务内容
            selectTemplate == 14 &&
            <>
              <div className="line"></div>
              <h3 className="apply-tit">服务内容</h3>
              <div className="underline" style={{ marginBottom: '16px' }}></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="媒体" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('mediaName', {
                      rules: [{ required: true, message: '请输入媒体名称' }]
                    })(<Input placeholder="请输入媒体名称" style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="服务费用总金额(含税)" labelCol={{ span: 8 }} wrapperCol={{ span: 14 }}>
                    {getFieldDecorator('totalServiceFeeInclusiveTax', {
                      rules: [{ required: true, message: '请输入数值' }]
                    })(<InputNumber placeholder="请输入数值" precision={2} style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
              </Row>
              <Table
                dataSource={dataRedSource}
                columns={redColumns}
                pagination={false}
                bordered
                size="small"
              />
              <h3 className="apply-tit" style={{ marginTop: '40px' }}>甲方开票信息</h3>
              <div className="underline" style={{ marginBottom: '16px' }}></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="公司名称" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('companyName', {
                      rules: [{ required: true, message: '请输入甲方公司名称' }]
                    })(<Input placeholder="请输入甲方公司名称" style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="发票类型" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('invoiceType', {
                      rules: [{ required: true, message: '请选择发票类型' }]
                    })(<Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="请选择发票类型"
                      style={{ width: 240 }}
                      dropdownMatchSelectWidth={false}
                    >
                      {
                        invoiceList.map((v, i) => {
                          return <Option value={v.name} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="税号" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('taxNumber', {
                      rules: [{ required: true, message: '请输入甲方税号' }]
                    })(<Input placeholder="请输入甲方税号" style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开票科目" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('expenseItem', {
                      rules: [{ required: true, message: '请选择开票科目' }]
                    })(<Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="请选择开票科目"
                      style={{ width: 240 }}
                      dropdownMatchSelectWidth={false}
                    >
                      {
                        expenseList.map((v, i) => {
                          return <Option value={v.name} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="地址" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('address', {
                      rules: [{ required: true, message: '请输入甲方联系地址' }]
                    })(<Input placeholder="请输入甲方联系地址" style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('bank', {
                      rules: [{ required: true, message: '请输入甲方开户行' }]
                    })(<Input placeholder="请输入甲方开户行" style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电话" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('phone', {
                      rules: [{ required: true, message: '请输入甲方联系电话' }]
                    })(<Input placeholder="请输入甲方联系电话" style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('bankAccount', {
                      rules: [{ required: true, message: '请输入甲方账号' }]
                    })(<Input placeholder="请输入甲方账号" style={{ width: 240 }} />)}
                  </FormItem>
                </Col>
              </Row>
            </>
          }
          {
            selectTemplate == 5 || selectTemplate == 10 || selectTemplate == 11 ? <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务内容</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={6}>
                  <FormItem label="投放媒体" className="apply-FormItem">
                    {getFieldDecorator('media', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={(value) => onSelectMedia(value, 0)}>
                      {
                        mediaList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="产品" className="apply-FormItem">
                    {getFieldDecorator('product', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} onSelect={() => onSelectChangeProduct(0)} dropdownMatchSelectWidth={false} onDropdownVisibleChange={(bool) => bool && onDropdownVisibleChangeProduct(0)}>
                      {
                        productList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <Icon type="plus" style={{ marginTop: 40, fontSize: '35px' }} onClick={add} />
                </Col>
                <Col span={6}>
                  {
                    (selectTemplate == 5 || selectTemplate == 10 || selectTemplate == 11) && ctrt_products.length > 0 && ctrt_products.some((v) => v.id == 0) ?
                      <Button style={{ marginTop: 40 }} onClick={() => onModification(0)}>修改信息</Button> :
                      <>
                        <span style={{ marginTop: '5px', marginRight: '5px', color: 'red', fontSize: '18px' }}>*</span>
                        <Button style={{ marginTop: 40 }} onClick={() => onMessage(0)} className={isShow ? 'contract-red' : ''}>补充信息</Button>
                        <div style={{ marginLeft: '14px', color: 'red' }} className={isShow ? 'contract-show' : 'contract-hide'}>请补充</div>
                      </>
                  }
                </Col>
              </Row>
              {keys.map((k, index) => (
                <Row gutter={24} key={index}>
                  <Col span={6}>
                    <FormItem
                      label="投放媒体"
                      key={k}
                    >
                      {getFieldDecorator(`medias[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={(value) => onSelectMedia(value, k)}>
                        {
                          mediaList.map((v, i) => {
                            return <Option value={v.id} key={i}>{v.name}</Option>
                          })
                        }
                      </Select>)}
                      {/* {keys.length > 1 ? (
                        <Icon
                          className="dynamic-delete-button"
                          type="minus"
                          onClick={() => remove(k)}
                          style={{ marginLeft: 40, fontSize: '35px' }}
                        />
                      ) : null} */}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem
                      label="产品"
                      key={k}
                    >
                      {getFieldDecorator(`products[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} onSelect={() => onSelectChangeProduct(k)} dropdownMatchSelectWidth={false} onDropdownVisibleChange={(bool) => bool && onDropdownVisibleChangeProduct(k)}>
                        {
                          productList.map((v, i) => {
                            return <Option value={v.id} key={i}>{v.name}</Option>
                          })
                        }
                      </Select>)}
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    {keys.length > 0 ? (
                      <>
                        <Icon type="plus" style={{ marginTop: 40, fontSize: '35px' }} onClick={add} />
                        <Icon
                          className="dynamic-delete-button"
                          type="minus"
                          onClick={() => remove(k)}
                          style={{ marginLeft: 40, fontSize: '35px' }}
                        />
                      </>
                    ) : null}
                  </Col>
                  <Col span={6}>
                    {
                      (selectTemplate == 5 || selectTemplate == 10 || selectTemplate == 11) && ctrt_products.length > 0 && ctrt_products.some((v) => v.id == k) ?
                        <Button style={{ marginTop: 40 }} onClick={() => onModification(k)}>修改信息</Button> :
                        <>
                          <span style={{ marginTop: '5px', marginRight: '5px', color: 'red', fontSize: '18px' }}>*</span>
                          <Button style={{ marginTop: 40 }} onClick={() => onMessage(k)} className={isShow ? 'contract-red' : ''}>补充信息</Button>
                          <div style={{ marginLeft: '14px', color: 'red' }} className={isShow ? 'contract-show' : 'contract-hide'}>请补充</div>
                        </>
                    }
                  </Col>
                </Row>
              ))}
              {(selectTemplate == 10 || selectTemplate == 11) && <Row gutter={24}>
                <Col span={6}>
                  <FormItem label="不含税价款" className="apply-FormItem apply-required">
                    <div>{buhan_shui}{buhan_shui ? '元' : ''}</div>
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="增值税额" className="apply-FormItem apply-required">
                    <div>{zengzhi_shui}{zengzhi_shui ? '元' : ''}</div>
                  </FormItem>
                </Col>
              </Row>}
              <Row gutter={24}>
                {
                  contractData.ctrt_product_all.taxRate && <Col span={6}>
                  <FormItem label="税率" style={{ display: 'inline-flex'}}>
                    {getFieldDecorator('taxRate', {
                      rules: [{ required: true, message: '请选择' }],
                      initialValue: '6',
                    })(<Radio.Group onChange={onChangeTaxRate}>
                        <Radio value="6">6%</Radio>
                        <Radio value="13">13%</Radio>
                      </Radio.Group>)}
                  </FormItem>
                </Col>
                }
                <Col span={6}>
                  <FormItem label="推广总费用" style={{ display: 'inline-flex'}}>
                    <div>{receiptFee}</div>
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="大写" style={{ display: 'inline-flex'}}>
                    <div>{dx ? dx : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务协议</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="甲方" className="apply-FormItem">
                    <div>{oaidName ? oaidName : detail.partA}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" className="apply-FormItem">
                    <div>{accountObj.fullName ? accountObj.fullName : detail.partB}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Acontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Bcontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Atel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Btel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Aemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Bemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    {getFieldDecorator('Aaddress', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    <div>{accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partB.address : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    {getFieldDecorator('Abank', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    <div>{accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partB.bank : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    {getFieldDecorator('Aaccount', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    <div>{accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partB.account : ''}</div>
                  </FormItem>
                </Col>
              </Row>
            </div> : selectTemplate == 2 ? <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务内容</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem label="推广版本" className="apply-FormItem">
                    {getFieldDecorator('version', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select placeholder="请选择" style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={onVersion}>
                      {
                        versionType.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <div
                    style={{ marginBottom: 30 }}
                  >服务内容：企业专属可信百科主页，包含功能：可信百科印章，立信企业证书展示，实名核验证书展示，企业风采版块权限，专属功能设置权限（二级域名，展示企业公众号，设置企业公告）基本信息设置权限（电话，邮箱，网址），
                  {
                      versionKey == 0 ? '站内关键词广告推荐。' :
                        versionKey == 1 ? '企业可信百科深度核验标识，深度核验资质展示' :
                          versionKey == 3 ? '特色服务标签，办学环境展示。' : '站内关键词广告推荐。'
                    }
                  </div>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem label="年限" className="apply-FormItem">
                    {getFieldDecorator('years', {
                      rules: [{ required: versionKey == 0 ? true : false, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                    <span>年</span>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <FormItem label="费用" className="apply-FormItem">
                    {getFieldDecorator('cost', {
                      rules: [{ required: versionKey == 0 ? true : false, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                    <span>元</span>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={24}>
                  <div style={{ marginBottom: 30 }}>
                    {
                      versionKey == 0 ? <div><span>赠送服务：</span>好搜one box 高级展示</div> :
                        versionKey == 1 ? <div>
                          <div><span>赠送服务：</span>以甲方企业全称触发360搜索one box高级展示</div>
                          <div className="service">以甲方企业简称（3个）触发360搜索one box高级展示</div>
                          <div className="service">360搜索推广结果专属标识（360搜索推广客户专享）</div>
                          <div className="service">360地图实际地址标注</div>
                          <div className="service">360右侧结果页公众号二维码展示</div>
                          <div className="service">来电商家秀展示服务</div>
                          <div className="service">站内关键词广告推荐</div>
                        </div> :
                          versionKey == 3 ? <div>
                            <div><span>赠送服务：</span>以甲方企业全称触发360搜索one box高级展示</div>
                            <div className="service">以甲方企业简称（4个）触发360搜索one box高级展示</div>
                            <div className="service">360搜索推广结果专属标识（360搜索推广客户专享）</div>
                            <div className="service">360地图实际地址标注</div>
                            <div className="service">360右侧结果页公众号二维码展示</div>
                            <div className="service">来电商家秀展示服务</div>
                            <div className="service">站内关键词广告推荐</div>
                            <div className="service">机构环境实地拍摄</div>
                            <div className="service">主营业务展示（办学特色等）</div>
                            <div className="service">机构行业许可资质展示核验</div>
                            <div className="service">专属行业定制化模板，快速编辑特色内容</div>
                            <div className="service">行业专职人员展示核验</div>
                            <div className="service">机构信用评价板块</div>
                            <div className="service">专业版征信报告（两份）</div>
                            <div className="service">信用舆情报告订阅</div>
                            <div className="service">信用圈负面及时推送</div>
                          </div> : <div><span>赠送服务：</span>好搜one box 高级展示</div>
                    }
                  </div>
                </Col>
              </Row>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务协议</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="甲方" className="apply-FormItem">
                    <div>{oaidName ? oaidName : JSON.stringify(contractData) != '{}' ? contractData.partA.name : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" className="apply-FormItem">
                    <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(contractData) != '{}' ? contractData.partB.name : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Acontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Bcontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Atel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Btel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Aemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Bemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    {getFieldDecorator('Aaddress', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    <div>{accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partB.address : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    {getFieldDecorator('Abank', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    <div>{accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partB.bank : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    {getFieldDecorator('Aaccount', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    <div>{accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partB.account : ''}</div>
                  </FormItem>
                </Col>
              </Row>
            </div> : selectTemplate == 3 ? <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务内容</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={4}>
                  <FormItem label="投放媒体" className="apply-FormItem">
                    {getFieldDecorator('media', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 150 }} dropdownMatchSelectWidth={false} onSelect={onSelectMedia}>
                      {
                        mediaList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="区域" className="apply-FormItem">
                    {getFieldDecorator('area', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 150 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="类别" className="apply-FormItem">
                    {getFieldDecorator('new_fee_type', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 150 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="结算方式" className="apply-FormItem">
                    {getFieldDecorator('settlementType', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Radio.Group onChange={(e) => onWay(e, 0)}>
                      <Radio value="zhe">折扣</Radio>
                      <Radio value="fan">返点</Radio>
                    </Radio.Group>)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="结算政策" className="apply-FormItem">
                    {getFieldDecorator('settlementNum1', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<InputNumber min={0} max={mayArr.some(v => v.id == 0) && (mayArr.filter(v => v.id == 0))[0].settlementType == 'zhe' ? 10 : undefined} step={0.1} onChange={(value) => onSettlementNum(value, 0)} />)}
                    <span>{mayArr.length > 0 && mayArr.some(v => v.id == 0) && (mayArr.filter(v => v.id == 0))[0].settlementType == 'zhe' ? '折' : '点'}</span>
                  </FormItem>
                  <div>
                    {
                      mayArr.length > 0 && mayArr.some(v => v.id == 0) && (mayArr.filter(v => v.id == 0))[0].settlementType == 'zhe' ?
                        <div style={{ width: 300 }}>（账户每充值100元，乙方须支付甲方 {mayArr.length > 0 && mayArr.some(v => v.id == 0) && (mayArr.filter(v => v.id == 0))[0].settlementNum2 ? (mayArr.filter(v => v.id == 0))[0].settlementNum2 : ''}元）</div> :
                        <div style={{ width: 380 }}>（账户每充值100元，账户显示100元+虚拟返点 {mayArr.length > 0 && mayArr.some(v => v.id == 0) && (mayArr.filter(v => v.id == 0))[0].settlementNum1 ? (mayArr.filter(v => v.id == 0))[0].settlementNum1 : ''}点）</div>
                    }
                  </div>
                </Col>
                <Col span={4}>
                  <Icon type="plus" style={{ marginTop: 40, fontSize: '35px' }} onClick={add} />
                </Col>
              </Row>
              {keys.map((k, index) => (
                <Row gutter={24} key={index}>
                  <Col span={4}>
                    <FormItem
                      label="投放媒体"
                      key={k}
                      className="apply-FormItem"
                    >
                      {getFieldDecorator(`medias[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 150 }} dropdownMatchSelectWidth={false} onSelect={onSelectMedia}>
                        {
                          mediaList.map((v, i) => {
                            return <Option value={v.id} key={i}>{v.name}</Option>
                          })
                        }
                      </Select>)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="区域" className="apply-FormItem" key={k}>
                      {getFieldDecorator(`areas[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" style={{ width: 150 }} maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="类别" className="apply-FormItem" key={k}>
                      {getFieldDecorator(`new_fee_types[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" style={{ width: 150 }} maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="结算方式" className="apply-FormItem">
                      {getFieldDecorator(`settlementTypes[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Radio.Group onChange={(e) => onWay(e, k)}>
                        <Radio value="zhe">折扣</Radio>
                        <Radio value="fan">返点</Radio>
                      </Radio.Group>)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="结算政策" className="apply-FormItem">
                      {getFieldDecorator(`settlementNum1s[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<InputNumber min={0} max={mayArr.some(v => v.id == k) && (mayArr.filter(v => v.id == k))[0].settlementType == 'zhe' ? 10 : undefined} step={0.1} onChange={(value) => onSettlementNum(value, k)} />)}
                      <span>{mayArr.length > 0 && mayArr.some(v => v.id == k) && (mayArr.filter(v => v.id == k))[0].settlementType == 'zhe' ? '折' : '点'}</span>
                    </FormItem>
                    <div>
                      {
                        mayArr.length > 0 && mayArr.some(v => v.id == k) && (mayArr.filter(v => v.id == k))[0].settlementType == 'zhe' ?
                          <div style={{ width: 300 }}>（账户每充值100元，乙方须支付甲方 {mayArr.length > 0 && mayArr.some(v => v.id == k) && (mayArr.filter(v => v.id == k))[0].settlementNum2 ? (mayArr.filter(v => v.id == k))[0].settlementNum2 : ''}元）</div> :
                          <div style={{ width: 380 }}>（账户每充值100元，账户显示100元+虚拟返点 {mayArr.length > 0 && mayArr.some(v => v.id == k) && (mayArr.filter(v => v.id == k))[0].settlementNum1 ? (mayArr.filter(v => v.id == k))[0].settlementNum1 : ''}点）</div>
                      }
                    </div>
                  </Col>
                  <Col span={4}>
                    {keys.length > 0 ? (
                      <>
                        <Icon type="plus" style={{ marginTop: 40, fontSize: '35px' }} onClick={add} />
                        <Icon
                          className="dynamic-delete-button"
                          type="minus"
                          onClick={() => remove(k)}
                          style={{ marginLeft: 40, fontSize: '35px' }}
                        />
                      </>
                    ) : null}
                  </Col>
                </Row>
              ))}
              <div className="line"></div>
              <h3 className="apply-tit">推广服务协议</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="甲方" className="apply-FormItem">
                    <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partA : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" className="apply-FormItem">
                    <div>{agent ? agent : JSON.stringify(detail) != '{}' ? detail.partB : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Acontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Bcontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Atel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Btel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Aemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Bemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    <div>{accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partA.address : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    {getFieldDecorator('Baddress', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    <div>{accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partA.bank : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    {getFieldDecorator('Bbank', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    <div>{accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partA.account : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    {getFieldDecorator('Baccount', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
            </div> : selectTemplate == 1 ? business == 2 ? <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务内容</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="甲方" className="apply-FormItem">
                    <div>{oaidName ? oaidName : JSON.stringify(detail) != '{}' ? detail.partA : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" className="apply-FormItem">
                    <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Acontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Bcontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Atel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="客服电话" className="apply-FormItem">
                    {getFieldDecorator('Btel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Aemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Bemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="手机号码" className="apply-FormItem">
                    {getFieldDecorator('Amobile', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    <div>{accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partB.address : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    {getFieldDecorator('Aaddress', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="邮编" className="apply-FormItem">
                    <div>{accountObj.zipcode || accountObj.zipcode == 0 || accountObj.zipcode == '' ? accountObj.zipcode : JSON.stringify(contractData) != '{}' ? contractData.partB.zipcode : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="邮编" className="apply-FormItem">
                    {getFieldDecorator('Azipcode', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="传真" className="apply-FormItem">
                    <div>{accountObj.fax || accountObj.fax == '' ? accountObj.fax : JSON.stringify(contractData) != '{}' ? contractData.partB.fax : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="传真" className="apply-FormItem">
                    {getFieldDecorator('Afax', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    <div>{accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partB.bank : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    <div>{accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partB.account : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <div className="line"></div>
              <h3 className="apply-tit">订购服务</h3>
              <div className="underline"></div>
              <div className="order">
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem label="购买类型" className="apply-checkbox">
                      {getFieldDecorator('purchase', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Checkbox.Group style={{ width: '100%' }}>
                        <Row>
                          <Col span={6}>
                            <Checkbox value="续费靓号">续费靓号</Checkbox>
                          </Col>
                          <Col span={6}>
                            <Checkbox value="增加工号">增加工号</Checkbox>
                          </Col>
                          <Col span={6}>
                            <Checkbox value="充值群发">充值群发</Checkbox>
                          </Col>
                          <Col span={6}>
                            <Checkbox value="短信充值">短信充值</Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={24}>
                    <FormItem label="企业号码">
                      {getFieldDecorator('enterprise', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="服务年限">
                      {getFieldDecorator('serveYears', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<RangePicker allowClear={false} style={{ width: 240 }} />)}
                    </FormItem>
                    <FormItem style={{ position: 'absolute', left: '360px', top: 0 }}>
                      {getFieldDecorator('serveYearsRemark', {
                        rules: [{ required: true, message: '请输入备注' }]
                      })(<Input placeholder="请输入备注" style={{ width: 240, marginLeft: '20px' }} />)}
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem label="业务服务" className="apply-FormItem">
                      {getFieldDecorator('businessService', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={24} style={{ display: 'flex', marginTop: '20px', alignItems: 'center' }}>
                    <FormItem label="服务费金额" className="apply-FormItem">
                      {getFieldDecorator('moneyServe', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" style={{ width: 240 }} onChange={onServiceAmount} maxLength={100} />)}
                    </FormItem>
                    <span>元</span>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={6}>
                    <FormItem label="推广总费用" className="apply-FormItem">
                      <div>{serviceAmount}</div>
                    </FormItem>
                  </Col>
                  <Col span={6}>
                    <FormItem label="大写" className="apply-FormItem">
                      <div>{serviceAmountDx}</div>
                    </FormItem>
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}></Col>
                </Row>
              </div>
            </div> : <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务协议</h3>
              <div className="underline"></div>
              <Row gutter={24} className="apply-client">
                <Col span={8}>
                  <FormItem label="甲方" className="apply-FormItem">
                    <div>{oaidName ? oaidName : JSON.stringify(detail) != '{}' ? detail.partA : ''}</div>
                  </FormItem>
                </Col>
                <Col span={8}>
                  <FormItem label="乙方" className="apply-FormItem">
                    <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : ''}</div>
                  </FormItem>
                </Col>
                <Col span={8}></Col>
              </Row>
            </div> : selectTemplate == 4 ? <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务内容</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={24}>
                  <p>甲方在本框架合同有效期内的“投放总金额”（指本框架合同有效期内乙方确认收入并在规定时间内收到甲方投放款的总金额）不低于
                    <Input className="frame-content" onChange={onChange1} value={value1} maxLength={100} />元（大写：<Input className="frame-content" value={dxValue1} style={{ width: 240 }} maxLength={100} />）人民币。甲方需在本框架合同生效后10日内，向乙方支付前款规定的“投放总金额”的<Input className="frame-content" onChange={onChange2} value={bfvalue} maxLength={100} />%，即人民币
                    <Input className="frame-content" onChange={onChange3} value={value2} maxLength={100} />元（大写：<Input className="frame-content" value={dxValue2} style={{ width: 240 }} maxLength={100} />）作为保证金。</p>
                  <p>乙方收到甲方支付的保证金和投放款后开始向甲方提供本合同项下的网络营销服务。在本合同有效期内，甲方向乙方支付的每笔投放款有<Input className="frame-content" onChange={onChange4} value={fdvalue} maxLength={100} />%返点。</p>
                </Col>
              </Row>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务媒体</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={6}>
                  <FormItem label="投放媒体" className="apply-frame">
                    {getFieldDecorator('media', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue dropdownMatchSelectWidth={false} onSelect={onSelectMedia}>
                      {
                        mediaList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
              </Row>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务协议</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="甲方" className="apply-FormItem">
                    <div>{oaidName ? oaidName : JSON.stringify(detail) != '{}' ? detail.partA : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" className="apply-FormItem">
                    <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Acontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Bcontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Atel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Btel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Aemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Bemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    {getFieldDecorator('Aaddress', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    <div>{accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partB.address : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    {getFieldDecorator('Abank', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    <div>{accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partB.bank : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    {getFieldDecorator('Aaccount', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    <div>{accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partB.account : ''}</div>
                  </FormItem>
                </Col>
              </Row>
            </div> : selectTemplate == 6 ? <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务内容</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={6}>
                  <FormItem label="投放媒体" className="apply-FormItem">
                    {getFieldDecorator('media', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={(value) => onSelectMedia(value, 0)}>
                      {
                        mediaList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <FormItem label="产品" className="apply-FormItem">
                    {getFieldDecorator('product', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} dropdownMatchSelectWidth={false} onDropdownVisibleChange={(bool) => bool && onDropdownVisibleChangeProduct(0)}>
                      {
                        productList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
                <Col span={6}>
                  <Icon type="plus" style={{ marginTop: 40, fontSize: '35px' }} onClick={add} />
                </Col>
                <Col span={6}></Col>
              </Row>
              {keys.map((k, index) => (
                <Row gutter={24} key={index}>
                  <Col span={6}>
                    <Form.Item
                      label="投放媒体"
                      key={k}
                    >
                      {getFieldDecorator(`medias[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} dropdownMatchSelectWidth={false} onSelect={(value) => onSelectMedia(value, k)}>
                        {
                          mediaList.map((v, i) => {
                            return <Option value={v.id} key={i}>{v.name}</Option>
                          })
                        }
                      </Select>)}
                      {/* {keys.length > 1 ? (
                        <Icon
                          className="dynamic-delete-button"
                          type="minus"
                          onClick={() => remove(k)}
                          style={{ marginLeft: 40, fontSize: '35px' }}
                        />
                      ) : null} */}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="产品"
                      key={k}
                    >
                      {getFieldDecorator(`products[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 240 }} dropdownMatchSelectWidth={false} onDropdownVisibleChange={(bool) => bool && onDropdownVisibleChangeProduct(k)}>
                        {
                          productList.map((v, i) => {
                            return <Option value={v.id} key={i}>{v.name}</Option>
                          })
                        }
                      </Select>)}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    {keys.length > 0 ? (
                      <>
                        <Icon type="plus" style={{ marginTop: 40, fontSize: '35px' }} onClick={add} />
                        <Icon
                          className="dynamic-delete-button"
                          type="minus"
                          onClick={() => remove(k)}
                          style={{ marginLeft: 40, fontSize: '35px' }}
                        />
                      </>
                    ) : null}
                  </Col>
                  <Col span={6}></Col>
                </Row>
              ))}
              <div className="line"></div>
              <h3 className="apply-tit">推广服务协议</h3>
              <div className="underline"></div>
              <Row gutter={24} className="apply-client">
                <Col span={12}>
                  <FormItem label="甲方" className="apply-FormItem">
                    {
                      clientSourceType == 0 ? <div>{oaidName ? oaidName : JSON.stringify(detail) != '{}' ? detail.partA : ''}</div> :
                        <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partA : ''}</div>
                    }
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" className="apply-FormItem">
                    {
                      clientSourceType == 0 ? <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : ''}</div> :
                        <div>{agent ? agent : JSON.stringify(detail) != '{}' ? detail.partB : ''}</div>
                    }

                  </FormItem>
                </Col>
              </Row>
            </div> : selectTemplate == 9 ? <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务内容</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={4}>
                  <FormItem label="结算方式" className="apply-FormItem">
                    {getFieldDecorator('settlementType', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem label="服务费" className="apply-FormItem">
                    {getFieldDecorator('settlementNum', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <>
                <div>委托账户及负责的人信息：<Icon type="plus" style={{ fontSize: '24px' }} onClick={add} /></div>
                <Row gutter={24}>
                  <Col span={4}>
                    <FormItem label="账户名称" className="apply-FormItem">
                      {getFieldDecorator('accountName', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="公司名称" className="apply-FormItem">
                      {getFieldDecorator('companyName', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="代运营媒体" className="apply-FormItem">
                      {getFieldDecorator('meiti', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="账号ID" className="apply-FormItem">
                      {getFieldDecorator('accountNum', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <FormItem label="姓名" className="apply-FormItem">
                      {getFieldDecorator('name', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="联系电话" className="apply-FormItem">
                      {getFieldDecorator('tel', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="微信号码" className="apply-FormItem">
                      {getFieldDecorator('weixin', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="QQ号码" className="apply-FormItem">
                      {getFieldDecorator('qq', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                </Row>
                <Row gutter={24}>
                  <Col span={4}>
                    <FormItem label="电子邮箱" className="apply-FormItem">
                      {getFieldDecorator('email', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                  <Col span={4}>
                    <FormItem label="传真号码" className="apply-FormItem">
                      {getFieldDecorator('fax', {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Input placeholder="请输入" maxLength={100} />)}
                    </FormItem>
                  </Col>
                </Row>
              </>
              {keys.map((k, index) => (
                <>
                  <div>委托账户及负责的人信息：{keys.length > 0 ? (<>
                    {/* <Icon type="plus" style={{ fontSize: '24px' }} onClick={add} /> */}
                    <Icon
                      className="dynamic-delete-button"
                      type="minus"
                      onClick={() => remove(k)}
                      style={{ fontSize: '24px' }}
                    />
                  </>) : null}</div>
                  <Row gutter={24}>
                    <Col span={4}>
                      <FormItem label="账户名称" className="apply-FormItem">
                        {getFieldDecorator(`accountNames[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="公司名称" className="apply-FormItem">
                        {getFieldDecorator(`companyNames[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="代运营媒体" className="apply-FormItem">
                        {getFieldDecorator(`meitis[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="账号ID" className="apply-FormItem">
                        {getFieldDecorator(`accountNums[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={4}>
                      <FormItem label="姓名" className="apply-FormItem">
                        {getFieldDecorator(`names[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="联系电话" className="apply-FormItem">
                        {getFieldDecorator(`tels[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="微信号码" className="apply-FormItem">
                        {getFieldDecorator(`weixins[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="QQ号码" className="apply-FormItem">
                        {getFieldDecorator(`qqs[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                  </Row>
                  <Row gutter={24}>
                    <Col span={4}>
                      <FormItem label="电子邮箱" className="apply-FormItem">
                        {getFieldDecorator(`emails[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      <FormItem label="传真号码" className="apply-FormItem">
                        {getFieldDecorator(`faxs[${k}]`, {
                          rules: [{ required: true, message: '请选择' }]
                        })(<Input placeholder="请输入" maxLength={100} />)}
                      </FormItem>
                    </Col>
                  </Row>
                </>
              ))}
              <div className="line"></div>
              <h3 className="apply-tit">推广服务协议</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="甲方" className="apply-FormItem">
                    <div>{agent ? agent : JSON.stringify(detail) != '{}' ? detail.partA : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" className="apply-FormItem">
                    <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partB : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Acontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Bcontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Atel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Btel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Aemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Bemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    {getFieldDecorator('Aaddress', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    <div>{accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partB.address : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    {getFieldDecorator('Abank', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    <div>{accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partB.bank : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    {getFieldDecorator('Aaccount', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    <div>{accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partB.account : ''}</div>
                  </FormItem>
                </Col> 
              </Row>
            </div> : (selectTemplate == 12 || selectTemplate == 13) ? <div>
              <div className="line"></div>
              <h3 className="apply-tit">推广服务内容</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={5}>
                  <FormItem label="投放媒体" className="apply-FormItem">
                    {getFieldDecorator('media', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 200 }} dropdownMatchSelectWidth={false} onSelect={onSelectMedia}>
                      {
                        mediaList.map((v, i) => {
                          return <Option value={v.id} key={i}>{v.name}</Option>
                        })
                      }
                    </Select>)}
                  </FormItem>
                </Col>
                <Col span={5}>
                  <FormItem label="返点" className="apply-FormItem">
                    {getFieldDecorator('settlementNum1', {
                      rules: [{ required: true, message: '请输入' }]
                    })(<Input 
                      style={{ width: 200 }}
                      placeholder="请输入"
                    />)}
                  </FormItem>
                </Col>
                <Col span={5}>
                  <Icon type="plus" style={{ marginTop: 40, fontSize: '35px' }} onClick={add} />
                </Col>
              </Row>
              {keys.map((k, index) => (
                <Row gutter={24}>
                  <Col span={5}>
                    <FormItem
                      label="投放媒体"
                      key={k}
                      className="apply-FormItem"
                    >
                      {getFieldDecorator(`medias[${k}]`, {
                        rules: [{ required: true, message: '请选择' }]
                      })(<Select showSearch={true} optionFilterProp="children" placeholder="请选择" labelInValue style={{ width: 200 }} dropdownMatchSelectWidth={false} onSelect={onSelectMedia}>
                        {
                          mediaList.map((v, i) => {
                            return <Option value={v.id} key={i}>{v.name}</Option>
                          })
                        }
                      </Select>)}
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    <FormItem label="返点" className="apply-FormItem">
                      {getFieldDecorator(`settlementNum1s[${k}]`, {
                        rules: [{ required: true, message: '请输入' }]
                      })(<Input
                        style={{ width: 200 }}
                        placeholder="请输入"
                      />)}
                    </FormItem>
                  </Col>
                  <Col span={5}>
                    {keys.length > 0 ? (
                      <>
                        <Icon type="plus" style={{ marginTop: 40, fontSize: '35px' }} onClick={add} />
                        <Icon
                          className="dynamic-delete-button"
                          type="minus"
                          onClick={() => remove(k)}
                          style={{ marginLeft: 40, fontSize: '35px' }}
                        />
                      </>
                    ) : null}
                  </Col>
                </Row>
              ))}
              <div className="line"></div>
              <h3 className="apply-tit">推广服务协议</h3>
              <div className="underline"></div>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="甲方" className="apply-FormItem">
                    <div>{accountObj.fullName ? accountObj.fullName : JSON.stringify(detail) != '{}' ? detail.partA : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="乙方" className="apply-FormItem">
                    <div>{agent ? agent : JSON.stringify(detail) != '{}' ? detail.partB : ''}</div>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Acontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系人" className="apply-FormItem">
                    {getFieldDecorator('Bcontacts', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Atel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系电话" className="apply-FormItem">
                    {getFieldDecorator('Btel', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Aemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="电子邮箱" className="apply-FormItem">
                    {getFieldDecorator('Bemail', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    <div>{accountObj.address || accountObj.address == '' ? accountObj.address : JSON.stringify(contractData) != '{}' ? contractData.partA.address : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="联系地址" className="apply-FormItem">
                    {getFieldDecorator('Baddress', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    <div>{accountObj.bankName || accountObj.bankName == '' ? accountObj.bankName : JSON.stringify(contractData) != '{}' ? contractData.partA.bank : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="开户行" className="apply-FormItem">
                    {getFieldDecorator('Bbank', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    <div>{accountObj.bankNo || accountObj.bankNo == '' ? accountObj.bankNo : JSON.stringify(contractData) != '{}' ? contractData.partA.account : ''}</div>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="账号" className="apply-FormItem">
                    {getFieldDecorator('Baccount', {
                      rules: [{ required: true, message: '请选择' }]
                    })(<Input placeholder="请输入" style={{ width: 240 }} maxLength={100} />)}
                  </FormItem>
                </Col>
              </Row>
            </div> : null
          }
          <div className="line"></div>
          <h3 className="apply-tit">其他信息</h3>
          <div className="underline"></div>
          <FormItem label="附件" className="apply-upload">
            <Row gutter={24}>
              <Col span={24}>
                <div className="dragger-box" ref={draggerRef}>
                  <Dragger
                    {...uploadFiles}
                  >
                    <p className="ant-upload-drag-icon">
                      <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">点击或将文件拖拽到这里上传</p>
                    <p className="ant-upload-hint">
                      附件支持格式：GIF、PNG、JPG、PDF、zip  支持添加多个附件，单个附件大小不超过15M，文件数量不超过20个。
                    </p>
                  </Dragger>
                </div>
              </Col>
            </Row>
          </FormItem>
          <FormItem label="备注" className="apply-FormItem apply-interval apply-required">
            {getFieldDecorator('note')(
              <Input.TextArea placeholder="请输入" maxLength={100} style={{ width: 1200 }} />
            )}
          </FormItem>
          <FormItem className="apply-btngroup">
            {/* <BtnGroup onCancel={handleReset} loading={saveLoading} className="apply-btngroups" /> */}
            <Button onClick={handleReset}>取消</Button>
            {/* {
              business == 1 || selectTemplate == 6 ? '' : <Button style={{ marginLeft: '15px' }} onClick={(e) => handleSubmit(e, 1)}>合同预览</Button>
            } */}
            <Button type="primary" style={{ marginLeft: '15px' }} onClick={(e) => handleSubmit(e, 0)}>确定</Button>
          </FormItem>
        </Form>
      </div>
      <Drawer
        title={<>
          <span>补充信息</span>
          {selectTemplate == 5 && <span style={{ marginLeft: '660px' }} onClick={onInfo}>我不会填<Icon type="exclamation-circle" style={{ fontSize: '14px' }} /></span>}
        </>}
        width={900}
        onClose={() => setVisible(false)}
        visible={visible}
        afterVisibleChange={afterVisibleChange}
      >
        <Spin spinning={formLoading}>
          {selectTemplate == 5 ? 
            <Supplementary
              way={way}
              savePreserve={savePreserve}
              onRelevance={onRelevance}
              cRef={productsRef}
              isOld={detail.isOld}
            /> : 
            <Supplementarys
              savePreserve={savePreserve}
              onRelevance={onRelevance}
              cRef={productsRef}
            />
          }
        </Spin>
      </Drawer>
      <Modal
        title="示例"
        visible={visibleInfo}
        onCancel={handleCancelInfo}
        footer={[]}
        width={1100}
        className="code-con"
      >
        <img src={Img} alt="" width={1000} height={510} />
      </Modal>
    </>
  )
};

export default Form.create({ name: 'dynamic_form_item' })(ContractAmend);
