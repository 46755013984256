/**
 * 模块名称: 预览合同
 * @author liujingxue@372163.com
 */

import React, { useEffect } from 'react'

const Preview = (props) => {

  const { history, location } = props

  useEffect(() => {

  }, [])

  return (
    <>
      <div>预览合同</div>
    </>
  )
}

export default Preview