/**
 * 模块名称: 补充信息
 * @author liujingxue@372163.com
 */

import React, { useState, useEffect, useImperativeHandle } from 'react'
import {
  message,
  Input,
  InputNumber,
  Form,
  Radio
} from 'antd'
import moment from 'moment'
import BtnGroup from '../../../components/BtnGroup'
import '../assets/explaine.scss'

const FormItem = Form.Item

let replenish = {
  tuiguangDuixiang: '',
  xinkai: '',
  yearFee: '',
  chongzhi: '',
  shishou: '',
  zhifugongzuori: '',
}

const Explains = (props) => {
  const { savePreserve, onRelevance, cRef, isOld } = props
  const { getFieldDecorator, setFieldsValue, validateFields } = props.form
  const [saveLoading, setSaveLoading] = useState(false)

  useEffect(() => {
    replenish = {
      tuiguangDuixiang: '',
      xinkai: '',
      yearFee: '',
      chongzhi: '',
      shishou: '',
      zhifugongzuori: '',
    }
  }, [])

  //清空补充信息
  const contentEmpty = () => {
    setFieldsValue({
      tuiguangDuixiang: '',
      xinkai: '',
      yearFee: '',
      chongzhi: '',
      shishou: '',
      zhifugongzuori: '',
    })
  }

  useImperativeHandle(cRef, () => ({
    // changeVal 就是暴露给父组件的方法
    changeVal: () => {
      contentEmpty()
    },
    changeAlter: (product) => {
      if (isOld == 1) {
        contentEmpty()
      } else {
        setFieldsValue({
          tuiguangDuixiang: product.tuiguangDuixiang,
          xinkai: product.xinkai,
          yearFee: product.yearFee,
          chongzhi: product.chongzhi,
          shishou: product.shishou,
          zhifugongzuori: product.zhifugongzuori,
        })
      }

    }
  }))

  //添加
  const handleSubmit = e => {
    e.preventDefault()
    validateFields((err, vals) => {
      if (!err) {
        // console.log(vals)
        replenish = {
          tuiguangDuixiang: vals.tuiguangDuixiang,
          xinkai: vals.xinkai,
          yearFee: vals.yearFee,
          chongzhi: vals.chongzhi,
          shishou: vals.shishou,
          zhifugongzuori: vals.zhifugongzuori,
        }
        savePreserve(replenish)
      } else {
        message.error('请填写必填内容')
      }
    })
  }

  return (
    <>
      <Form onSubmit={handleSubmit} className="apply-form">
        <div className="apply-spin">
          <div className="apply-drawer">
            <FormItem label="甲方推广对象" className="apply-supplement apply-service">
              {getFieldDecorator('tuiguangDuixiang', {
                rules: [{ required: true, message: '请输入' }]
              })(<Input placeholder="请输入" style={{ width: 320 }}/>)}
            </FormItem>
            <FormItem label="新开/续费" className="apply-supplement">
              {getFieldDecorator('xinkai', {
                rules: [{ required: true, message: '请选择' }]
              })(<Radio.Group>
                <Radio value="new">新开</Radio>
                <Radio value="reNew">续费</Radio>
              </Radio.Group>)}
            </FormItem>
            <FormItem label="年服务费" className="apply-supplement apply-service">
              {getFieldDecorator('yearFee', {
                rules: [{ required: true, message: '请输入' }]
              })(<InputNumber placeholder="请输入" style={{ width: 240 }} />)}
              <span style={{ marginLeft: '10px'}}>元</span>
            </FormItem>
            <FormItem label="账户充值金额" className="apply-supplement apply-service">
              {getFieldDecorator('chongzhi', {
                rules: [{ required: true, message: '请输入' }]
              })(<InputNumber
                  placeholder="请输入"
                  style={{ width: 240 }}
                />)}
                <span style={{ marginLeft: '10px'}}>元</span>
            </FormItem>
            <FormItem label="推广费用总计（实收）">
              {getFieldDecorator('shishou', {
                rules: [{ required: true, message: '请选择' }]
              })(<InputNumber placeholder="请输入" style={{ width: 240 }} />)}
              <span style={{ marginLeft: '10px'}}>元</span>
            </FormItem>
            <FormItem label="推广费用支付时间" className="apply-supplement apply-service">
              <span style={{ marginRight: '15px'}}>合同签订后</span>
              {getFieldDecorator('zhifugongzuori', {
                rules: [{ required: true, message: '请选择' }]
              })(<InputNumber placeholder="请输入" style={{ width: 240 }} />)}
              <span style={{ marginLeft: '15px'}}>工作日内</span>
            </FormItem>
          </div>
          <div className="apply-button" >
            <BtnGroup onCancel={onRelevance} confirmName="保存" Loading={saveLoading} />
          </div>
        </div>
      </Form>
    </>
  )
}

export default Form.create()(Explains)